import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class Kruger extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render_palette() {

    const colors = [
      '#8bc559',
      '#6fa2d6',
      '#0571df',
      '#fdc500',
      '#f16450',
      '#07d7a9',
      '#13c8e9',
      '#f8146f',
      '#3a5d80',
      '#ff543b',
      '#37b33a',
      '#b381f1'
    ]

    return (
      <div className="palette projectsectionhidden">
        {colors.map(hex => (
          <div className="color" style={{ backgroundColor: hex }} key={hex}>
            <div>
              <h4>{this.props.t('COLOUR')}</h4>
              <h4>{hex}</h4>
            </div>
          </div>
        ))}
      </div>
    )
  }

  render_illustrations() {

    const illustrations = [
      require('assets/images/projects/kruger/i_1.png'),
      require('assets/images/projects/kruger/i_2.png'),
      require('assets/images/projects/kruger/i_3.png'),
      require('assets/images/projects/kruger/i_4.png'),
      require('assets/images/projects/kruger/i_5.png'),
      require('assets/images/projects/kruger/i_6.png'),
      require('assets/images/projects/kruger/i_7.png'),
      require('assets/images/projects/kruger/i_8.png'),
      require('assets/images/projects/kruger/i_9.png'),
      require('assets/images/projects/kruger/i_10.png'),
      require('assets/images/projects/kruger/i_11.png'),
      require('assets/images/projects/kruger/i_12.png')
    ]

    return (
      <div className="illustrations projectsectionhidden">
        <div className="container">
          {
            illustrations.map((item, i) => (

              <div className="item" key={i}>
                <img src={item} alt="illustration" />
              </div>

            ))
          }
        </div>
      </div>
    )

  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-kruger" className="projectcontainer projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className={"banner projectsectionhidden" + (i18n.language === 'fr' ? " banner-fr" : "")}></div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
            <h4>{t('Event Management')}</h4>
            <h4>{t('Experiential')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img src={require('assets/images/projects/kruger/KrugerLogo' + french_version + '.png')} alt="kruger logo" />
          <p>
            {i18n.language === 'en' ? "While we are involved in various Sales & Marketing projects for Kruger Products - Canada's leading tissue manufacturer, an area where we have had the biggest impact is within their HR function. This has included writing and designing internal communications, creating an employee welcome package, and producing their annual top employer submission, among other things." : "Bien que nous soyons impliqués sur divers aspects des ventes & des projets de commercialisation des produits Kruger (le premier fabricant de papier au Canada), nous avons eu le plus d'impact au sein de la division des ressources humaines. Nous avons travaillé, entre autes, sur la rédaction et la conception de leur communication interne, la mise sur pied d'une brochure d'accueil pour les nouveaux employé.e.s et la production de leur présentation annuelle du meilleur employeur."}
          </p>
        </div>

        <div className="laptop projectsectionhidden">
          <img src={require('assets/images/projects/kruger/HR_Macbook.png')} alt="laptop wireframe" />
        </div>

        {this.render_palette()}

        {this.render_illustrations()}

        <div className="wireframe projectsectionhidden">
          <div className="indicator">{t('Internal Website')}</div>
          <img src={require('assets/images/projects/kruger/wireframe-skeleton.png')} alt="project wireframe" />
        </div>

        <div className="phone-mockup projectsectionhidden">
          <img src={require('assets/images/projects/kruger/HR_Phone.png')} alt="project mockup" />
        </div>

        <div className="tablet-mockup projectsectionhidden">
          <div className="container">
            <img src={require('assets/images/projects/kruger/HR_iPad_Horizontal.png')} alt="tablet mockup" />
            <img src={require('assets/images/projects/kruger/HR_iPad_Vertical.png')} alt="tablet mockup vertical" />
          </div>
        </div>

        <div className="wireframe-box projectsectionhidden">
          <div className="indicator">{t('Top Employer Application')}</div>
          <img src={require('assets/images/projects/kruger/wireframe-box' + french_version + '.png')} alt="project wireframe 2" />
        </div>

        <div className="products1 projectsectionhidden">
          <img src={require('assets/images/projects/kruger/100_Everything.jpg')} alt="kruger product item" />
        </div>

        <div className="products2 projectsectionhidden">
          <img src={require('assets/images/projects/kruger/100_BookCover.jpg')} alt="kruger product item" />
          <img src={require('assets/images/projects/kruger/100_BookOpened.jpg')} alt="kruger product item" />
        </div>

        <div className="products3 projectsectionhidden">
          <img src={require('assets/images/projects/kruger/100_Box.jpg')} alt="kruger product item" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Policy Announcement Video')}</div>
        </div>

        <div className="productvideo projectsectionhidden">

          <video width="100%" height="auto" controls={true} poster={require('assets/images/projects/_covers/kruger-policy.png')}>
            <source src="https://player.vimeo.com/external/383826781.hd.mp4?s=74ffbd7a04a4870ce0b58cc387373c05a7005400&profile_id=175" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>

        </div>

      </div>
    )
  }
}

export default withTranslation()(Kruger);

import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class Kruger extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div id="project-tea" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img id="logo1" src={require('assets/images/projects/finefettletea/logo.png')} alt="project logo" />
          <img id="logo2" src={require('assets/images/projects/finefettletea/fruits.jpg')} alt="project logo 2" />
          <p>
            {i18n.language === "en" ? "To be \"in fine fettle\" means to be in good health. A Naturopathic Doctor approached us to help create the logo, brand identity, and packaging for her line of wellness teas named after this concept. Each offering has a unique colourway and watercolour illustration bearing the attributes of that blend." : "L'expression fine fettle vient du vieil anglais et signifie être en bonne santé. Une médecin naturopathe a contacté Human Contact afin de créer le logo, l'identité de la marque et l'emballage des thés de sa ligne bien-être qui portent le même nom. Chaque offre suit une palette unique adornée d'une aquarelle qui symbolise les attributs de ce mélange. "}
          </p>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .indicatorcontainer').scrollIntoView({
            behavior: 'smooth'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
          </div>
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">Logo</div>
        </div>

        <div className="blackandwhite projectsectionhidden">
          <img src={require('assets/images/projects/finefettletea/grid.png')} alt="project black and white logos" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Color Palette')}</div>
        </div>

        <div className="colorgrid projectsectionhidden">
          <div className="wallpaper"><img src={require('assets/images/projects/finefettletea/wallpaper1.jpg')} alt="palette 1" /></div>
          <div className="wallpaper"><img src={require('assets/images/projects/finefettletea/wallpaper2.jpg')} alt="palette 2" /></div>
          <div className="wallpaper"><img src={require('assets/images/projects/finefettletea/wallpaper3.jpg')} alt="palette 3" /></div>
          <div className="wallpaper"><img src={require('assets/images/projects/finefettletea/wallpaper4.jpg')} alt="palette 4" /></div>
        </div>

        <div className="fontface projectsectionhidden">
          <div className="indicator">{t('Typography')}</div>
          <img src={require('assets/images/projects/finefettletea/fontface.png')} alt="project font" />
        </div>

        <div className="photo1 projectsectionhidden">
          <div className="indicator">{t('Package Design')}</div>
          <img src={require('assets/images/projects/finefettletea/photo1.jpg')} alt="project relax and renew" />
        </div>

        <div className="photo2 projectsectionhidden">
          <img src={require('assets/images/projects/finefettletea/bags1.png')} alt="product bag 1" />
          <img src={require('assets/images/projects/finefettletea/bags2.png')} alt="product bag 2" />
          <img src={require('assets/images/projects/finefettletea/bags3.png')} alt="product bag 3" />
        </div>

        <div className="photo3 projectsectionhidden">
          <div className="indicator">{t('Label')}</div>
          <div>
            <img src={require('assets/images/projects/finefettletea/photo2-1.jpg')} alt="project label 1" />
            <img src={require('assets/images/projects/finefettletea/photo2-2.jpg')} alt="project label 2" />
          </div>
        </div>

        <div className="photo4 projectsectionhidden">
          <div className="indicator">{t('Splash Screen')}</div>
          <img src={require('assets/images/projects/finefettletea/laptop.png')} alt="project label" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(Kruger);

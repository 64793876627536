import React from 'react';
import gsap from "gsap";

class Loader extends React.Component{

  state = {
    loaderVisible: true,
    animationComplete: false
  }

  componentDidMount() {

    const message = document.querySelector('.loader .cover .message');

    this.setState({ animationComplete: true })
    gsap.to(message, 0.4, { delay: 2.4, opacity: 1 })

    setTimeout(() => {

      document.querySelector('.loader').classList.add('start');
      gsap.to(message, 0.4, {
        delay: 1.4 + 0.2,
        opacity: 0,
        top: '48%'
      });

      setTimeout(() => { this.setState({ animationComplete: true }) }, 600) // loader completely finished

    }, 2400);

  }

  componentDidUpdate(prevProps) {

    if(this.props.contentLoaded !== prevProps.contentLoaded && this.props.contentLoaded === true) {

      // hide loader when API content loaded AND loader animation is complete
      if(this.checkFinished) return

      this.checkFinished = setInterval(() => {

        if(this.state.animationComplete && this.props.contentLoaded) {

          gsap.to(document.querySelector('.loader'), 0.8, { delay: 1.4, left: '100%' });
          this.props.onLoad()

          clearInterval(this.checkFinished)
        }

      }, 100)


    }

  }

  render() {
    const { loaderVisible } = this.state;

    return (
      <div className="loader" style={{ display: loaderVisible ? 'block' : 'none' }}>

        <div className="cover">
          <div className="message" style={{display: 'none'}}>A PERSONAL APPROACH TO YOUR BUSINESS</div>
        </div>

        <div className="content">
          <div className="bar"></div>
          <div className="thumb">
            <img src={require('assets/images/icons/fingerprint.png')} alt="fingerprint logo" />
          </div>
          <h4>Humancontact Inc.</h4>
        </div>

      </div>
    )
  }
}

export default Loader;

const Fixes = () => {
  window.hc.device.isMobile = (
    navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  )

  window.hc.device.browser = {
    opera: (navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1,
    chrome: navigator.userAgent.indexOf("Chrome") !== -1,
    safari: navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1,
    firefox: navigator.userAgent.indexOf("Firefox") !== -1,
    ie: (navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true ), // IE > 10
    edge: (navigator.userAgent.indexOf("edge") > -1) || (navigator.userAgent.indexOf('Edg') > -1)
  }

  // NOTE: Three js renderer if(window.hc.device.isMobile || window.hc.device.browser.ie) - check Scene.js

}

export default Fixes;

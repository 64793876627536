import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class RunOttawa extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()

    gsap.fromTo(document.querySelector('#project-runottawa .banner img'), { opacity: 0, bottom: 220 }, { opacity: 1, bottom: 180, delay: 0.8 })
    gsap.fromTo(document.querySelector('#project-runottawa .banner h1'), { opacity: 0, y: 20 }, { opacity: 1, y: 0, delay: 0.5 })
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-runottawa" className="projectcontainer projectdetailcontainer">

        <CloseButton />

        <div className="banner">
          <img src={require('assets/images/projects/runottawa/banner-phone.png')} />
          <h1>{t('RUN')}<br/>{t('OTTAWA')}</h1>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
            <h4>{t('Experiential')}</h4>
          </div>
        </div>

        <div className="description">
          <img src={require('assets/images/projects/runottawa/description-logo' + french_version + '.png')} alt="Tamarak logo" />
          <p>
            {i18n.language === 'en' ? "Tamarack Ottawa Race Weekend is Canada's largest running event. We were tasked with helping Run Ottawa deliver a best-in-class experience to its participants, spectators, and volunteers via a new smartphone app. This included helping select the most effective platform, to design and content creation, as well as finding ways to increase interactivity and engagement via exclusive selfie filters and tailored notifications. This work with Run Ottawa has since led to several other branding and design projects aimed at staying connected with their audience throughout the year." : "La fin de semaine des courses de Tamarack d'Ottawa est le plus grand événement de course à pied au Canada. Nous avons été chargés d'aider Courez Ottawa à offrir une expérience de premier ordre à ses participants, spectateurs et bénévoles via une nouvelle application mobile. Nous avons participé à la sélection de la plateforme la plus efficace, à la conception et à la création du contenu, et nous avons trouvé des moyens d'accroître l'interactivité et l'engagement par le biais de filtres à selfie exclusifs et de notifications personnalisées. Ce travail avec Courez Ottawa a depuis conduit à plusieurs autres projets de création de marque et de conception visant à assurer un lien avec leur public tout au long de l'année."}
          </p>
        </div>

        <div id="palette">
          <div className="indicatorcontainer">
            <div className="indicator">{t('Vibrant Custom Colour Palette')}</div>
          </div>
          <div id="colors">
            <div></div>
            <div><span>#EC2890</span></div>
            <div><span>#C01E8D</span></div>
            <div><span>#4B499D</span></div>
            <div><span>#FFE300</span></div>
            <div><span>#F4831F</span></div>
            <div><span>#40B9EB</span></div>
          </div>
        </div>

        <div className="wireframe-box">
          <div className="indicator">{t('App Wireframe')}</div>
          <img src={require('assets/images/projects/runottawa/wireframe.png')} alt="project wireframe 2" />
        </div>
        
        <div className="splash-screen">
          <div className="indicator">{t('Splash Screen')}</div>
          <img src={require('assets/images/projects/runottawa/splash-phone.png')} alt="project phone splash page" />
        </div>

        <div className="role-screen">
          <div className="indicator">{t('Role Selector')}</div>
          <img src={require('assets/images/projects/runottawa/splash-role.png')} alt="project phone role page" />
        </div>

        <div className="home-screen">
          <img src={require('assets/images/projects/runottawa/splash-home.png')} alt="project phone role page" />
        </div>

        <div className="selfie-filters">
          <div className="indicator">{t('Exclusive Selfie Filters')}</div>
          <img src={require('assets/images/projects/runottawa/splash-selfie.png')} alt="project phone selfie page" />
        </div>

        <div className="champ">
          <img src={require('assets/images/projects/runottawa/08_Title' + french_version + '.png')} alt="project phone selfie page" />
        </div>
        
        <div className="brand-process">
          <div className="indicator">{t('Branding Process')}</div>
          <img src={require('assets/images/projects/runottawa/09_LogoProcess.png')} alt="project phone selfie page" />
        </div>

        <div className="brand-palette">
          <div><span>#FF0000</span></div>
          <div><span>#F38BA0</span></div>
          <div><span>#F8E400</span></div>
          <div><span>#B9E4DB</span></div>
          <div><span>#1B4D4E</span></div>
          <div><span>#1D3847</span></div>
        </div>

        <img id="brand-palette-images" src={require('assets/images/projects/runottawa/10_ColourExploration.png')} alt="Brand palette demos" />

        <div className="final-logos">
          <div className="indicator">{t('Final Logos')}</div>
          <img src={require('assets/images/projects/runottawa/11_FinalLogo.png')} alt="project phone selfie page" />
        </div>

        <div className="social-assets">
          <div className="indicator">{t('Social Assets & Banners')}</div>
          <div className="color-container">
            <img className="bg" src={require('assets/images/projects/runottawa/12_BG_Red.png')} alt="Social assets background colors" />
            <img className="phone1" src={require('assets/images/projects/runottawa/12_Phone.png')} alt="Social assets phone 1" />
          </div>
          <div className="color-container">
            <img className="bg" src={require('assets/images/projects/runottawa/13_BG_Beige.png')} alt="Social assets background colors" />
            <img className="phone2" src={require('assets/images/projects/runottawa/13_Phone_IG.png')} alt="Social assets phone 2" />
          </div>
          <div className="color-container">
            <img className="bg" src={require('assets/images/projects/runottawa/14_BG_Blue.png')} alt="Social assets background colors" />
            <img className="phone3" src={require('assets/images/projects/runottawa/14_Tablet_Facebook.png')} alt="Social assets phone 3" />
          </div>

          <img className="phone4" src={require('assets/images/projects/runottawa/15_Laptop_Web.png')} alt="Social assets phone 4" />
        </div>
        
      </div>
    )
  }
}

export default withTranslation()(RunOttawa);

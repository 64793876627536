import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = require('./translation.json');

// the translations
// (tip move them in a JSON file and import them)

const detection = {
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',

  // cache user language
  caches: ['localStorage'],
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    cleanCode: true,
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
import gsap from 'gsap';
import $ from 'jquery';

// NOTE: These are the shared animations for all project detail <-> project list pages

// Can't put these in Work.js because project detail components might load without project list component
// i.e. User visits /work/kruger-products directly // Refer to App.js routing

const ProjectAnimations = () => {

  // transitioning between project list & project detail pages
  window.hc.timelines.projectDetailIn = () => {
    gsap.to(document.querySelectorAll('.backgroundscene, .menus'), 0, { display: 'none' })
    gsap.to(document.querySelector('#content'), 0, { position: 'unset', overflow: 'scroll' })
    gsap.to(document.querySelector('body'), 0.4, { opacity: 1 })

    $('html').css('overflow',' scroll')

    if(window.hc.device.browser.chrome || window.hc.device.browser.safari || window.hc.device.browser.firefox) {
        document.querySelector('*').scrollTo(0,0)
        window.scrollTo(0,0)
      }

    if(!window.hc.device.browser.chrome && !window.hc.device.browser.safari & document.querySelector('#projectdetailglass'))
      document.querySelector('#projectdetailglass').classList.add('black') // glass blur animation only on chrome & safari

    $('#project-kruger, #project-kfc, #project-auction, #project-bdo, #project-carrot, #project-tea, #project-rexel').addClass('loaded')

  }

  // ... reverse everything above
  window.hc.timelines.projectDetailOut = () => {

    gsap.to(document.querySelectorAll('.backgroundscene, .menus'), 0, { display: 'block' })
    gsap.to(document.querySelector('#content'), 0, {
      position: 'absolute',
      overflow: 'inherit'
    })

    $('html').css('overflow',' scroll')

    document.querySelector('*').scrollTo(0,0)

  }

}

export default ProjectAnimations;

import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

class Terms extends React.Component {
  componentDidMount() {
    window.hc.screen.updateIndex(0)
  }

  render() {
    const { i18n } = this.props;
    if (i18n.lang === 'en') {
        return (
            <div className={"text-page" + (window.hc.device.isMobile ? ' ismobile' : '')}>
              <Helmet>
                <title>HUMANCONTACT | Privacy Policy</title>
                <meta name="HUMANCONTACT" content="Privacy Policy" />
              </Helmet>
      
              <h1>
                  HUMANCONTACT WEBSITE
              </h1>
              <h2>
                  Privacy Policy
              </h2>
              <h2>
                  ENGLISH
              </h2>
              <h2>
                  V03 – September 2, 2020
              </h2>
              <p>
                  Human Contact Inc. / The Merge Portal Inc. (“Merge”, “we” or “us”), the
                  operator of the HUMANCONTACT website (the “Website”) on behalf of itself
                  and of Human Contact Inc. / The Merge Portal Inc. (each, a “Company” and
                  collectively the “Companies”), is committed to protecting any personal
                  information which you may provide while using, or that may be obtained from
                  your use of, the Website or the emails sent by either Company to you. The
                  purpose of this privacy policy (the “Policy”) is to: (i) inform you of the
                  privacy practices and policies of the Companies, including how and why your
                  personal information is collected, how your personal information is used
                  and with which parties we may share your personal information; and (ii)
                  provide you with contact information should you have questions or concerns
                  about the practices of the Companies. This Policy is accessible via the
                  Website.
              </p>
              <p>
                  This Website is for use only by residents of Canada. By using the Website,
                  you consent to the collection, storage, use, disclosure and retention of
                  your personal information in accordance with this Policy. If you do not
                  agree with this Policy, you should not access or use the Website.
              </p>
              <p><strong>
                  Overview of Our Privacy Practice
                  </strong></p>
              <p>
                  Our privacy practices are summarized here. Under this Policy, the
                  Companies:
              </p>
              <ol type="a">
                  <li>
                      <p>
                          Requires that you confirm that you are the age of majority in your
                          province of residence, or operating with the approval of your
                          guardian who is age of majority in your province of residence;
                      </p>
                  </li>
                  <li>
                      <p>
                          May collect data through your Website usage, your internet protocol
                          (IP) address, the Website cookies and email web beacons;
                      </p>
                  </li>
                  <li>
                      <p>
                          Collects non-identifiable information regarding Website users (such
                          as the date and time you accessed the website);
                      </p>
                  </li>
                  <li>
                      <p>
                          Shares this information with its authorized employees, agents,
                          service providers and legal representations, and may also share
                          this information with third parties that we engage to provide
                          products and services on our behalf;
                      </p>
                  </li>
                  <li>
                      <p>
                          May use, share and disclose the information collected by one
                          Company to the other Company in accordance with this Policy;
                      </p>
                  </li>
                  <li>
                      <p>
                          Except as set out in (d) and (e), does not share your personal
                          information with third parties without your consent, unless
                          authorized or required by law or as disclosed to you when the
                          information is collected;
                      </p>
                  </li>
                  <li>
                      <p>
                          Collects and uses this information to ensure that you and the
                          recipient of our emails are of legal age to <strong>purchase tobacco
                          products</strong> in their province of residence, comply with applicable
                          laws and regulations, to administer the Website, to protect the
                          Companies and Website users from fraud, to cross-reference with
                          personal information provided by you to our sales representatives
                          to ensure its accuracy, and to communicate with you;
                      </p>
                  </li>
                  <li>
                      <p>
                          Stores your personal information with Human Contact Inc. or The
                          Merge Portal AWS server , and may store and process your personal
                          information collected through the Website on the servers of our
                          third party service providers including Qumu, WebEx, Zoom,
                          Microsoft; however, the third party service providers are only
                          permitted to store your personal information, and are not otherwise
                          authorized to collect, use or disclose your personal information,
                          except in accordance with the purposes set out in this Policy.
                      </p>
                  </li>
                  <li>
                      <p>
                          Allows you to withdraw your consent to the collection, use and
                          disclosure of your personal information at any time; and
                      </p>
                  </li>
                  <li>
                      <p>
                          Mitigates the risk and impact of potential harms involved in the
                          disclosure of personal information through the adoption of
                          reasonable physical, electronic and managerial procedures
                          appropriate to the sensitivity of the data in an effort to
                          safeguard the personal information you provide to us. Even with
                          these measures, there is a residual risk of the loss of,
                          unauthorized access to or unauthorized disclosure of your
                          information which could result in financial or other harm to you.
                      </p>
                  </li>
              </ol>
              <p>
                  Further information with respect to our privacy practices is set out below.
              </p>
              <p><strong>
                  1. Personal Information Collected by the Companies
              </strong></p>
              <p>
                  Before you first access the Website, the Companies will provide you with a
                  username and a temporary password. When you log into the Website, you will
                  be asked to confirm that you are of the age of majority in your province of
                  residence and that you are an invited attendee, partner, customer or
                  stakeholder conducting business with one or all of the Companies. Once
                  logged in, you will have access to a profile that was populated by the
                  Companies based on the information you have provided to the Companies in
                  the past when accessing or using other websites of the Companies. The
                  personal information pre-populated in your profile may include information
                  such as your: name, address, billing address and contact information. In
                  some cases, the Companies may ask you to provide additional information
                  after initial registration, including your email address.
              </p>
              <p>
                  The Companies may also collect your Website usage data and your internet
                  protocol (IP) address, and other similar information that you provide to
                  the Companies via the Website, including information collected through the
                  use of cookies on your browser. As well, we may collect information
                  relating to the emails that the Companies send to you, such as when you
                  open the email, through the use of single pixel gifs (also called web
                  beacons) or other tracking technologies.
              </p>
              <p>
                  You should exercise discretion when disclosing your personal information to
                  us, and should not disclose sensitive or confidential information that you
                  wish to remain private or that may require you to impose specific data
                  security or data protection obligations on us.
              </p>
              <p><strong>
                  2. Non-Identifiable Information Automatically Collected
              </strong></p>
              <p>
                  The Website may automatically collect certain non-identifiable information
                  regarding Website users, such as the date and time you access the Website,
                  the consent to the Terms and Conditions of Use of the Website, the Internet
                  address of the website from which you linked directly to the Website, the
                  operating system you are using, the sections of the Website you visit, the
                  Website pages read and images viewed, and the materials you download from
                  the Website. The Companies may collect, use, transfer and disclose
                  aggregated and/or non-personal information.
              </p>
              <p><strong>
                  3. Cookies
              </strong></p>
              <p>
                  The Website uses “cookies”, a technology that installs information on a
                  website user’s computer to permit the Website to recognize future visits
                  using that computer. Cookies enhance the convenience and use of the
                  Website. For example, the information provided through cookies is used to
                  recognize you as a previous user of the Website, to track your activity at
                  the Website in order to respond to your needs, to offer personalized web
                  page content and information for your use, and to otherwise facilitate your
                  Website experience. You may choose to decline cookies if your browser
                  permits, but doing so may affect your use of the Website and your ability
                  to access certain features of the Website.
              </p>
              <p><strong>
                  4. Web Beacons
              </strong></p>
              <p>
                  The emails that are sent to you may also include web beacons. Web beacons
                  allow the Companies to collect information about when email recipients open
                  the emails sent to them, what parts of the email they review, their IP
                  address, browser or email client type and other similar details. Some of
                  this information may be correlated with recipients’ email addresses. The
                  Companies use this information to review how recipients are responding to
                  the emails sent to them.
              </p>
              <p><strong>
                  5. Purpose of Collection and Use of Your Personal Information
              </strong></p>
              <p>
                  Personal information that you supply during registration or that is
                  collected through your use of the Website or from the emails sent to you by
                  the Companies is collected and used for the following purposes:
              </p>
              <ol type="i">
                  <li>
                      <p>
                          To ensure that users of the Website and recipients of emails from
                          the Companies are of the legal age in their province of residence;
                      </p>
                  </li>
                  <li>
                      <p>
                          As permitted by, and to comply with, applicable laws and
                          regulations;
                      </p>
                  </li>
                  <li>
                      <p>
                          To administer the Website, including the Rewards, Games and
                          Contests sections, and to provide support for the services
                          available through the Website and to manage the emails sent by the
                          Companies;
                      </p>
                  </li>
                  <li>
                      <p>
                          To protect the Companies and Website users against fraud;
                      </p>
                  </li>
                  <li>
                      <p>
                          To contact you and to send you information about our products, our
                          activities, programs and events (provided that you confirm that
                          your email address is only accessible to adults in your province of
                          residence and that you are an invited attendee, partner, customer
                          or stakeholder conducting business with one or all of the
                          Companies), unless and until you opt-out of receiving such
                          information by contacting us at
                          <a href="mailto:contact@humancontact.com">
                              contact@humancontact.com
                          </a>
                          ;
                      </p>
                  </li>
                  <li>
                      <p>
                          To send you online surveys;
                      </p>
                  </li>
                  <li>
                      <p>
                          Other purposes permitted by law.
                      </p>
                  </li>
              </ol>
              <p>
                  Personal information collected or disclosed to one Company may also be used
                  by the other Company in accordance with this Policy.
              </p>
              <p><strong>
                  6. Disclosure of Personal Information
              </strong></p>
              <p>
                  The Companies will keep personal information that you provide to them
                  private, and will disclose such personal information only in accordance
                  with this Policy.
              </p>
              <p>
                  The Companies do not otherwise sell, rent, or lease to third parties any
                  personal information collected from you. The Companies may disclose or
                  share personal information with their authorized employees, agents, service
                  providers and legal representatives for the purposes described in this
                  Policy. They may also share your personal information with third parties
                  that they engage to provide products and services on their behalf.
              </p>
              <p>
                  The Companies will not otherwise share your personal information with third
                  parties without your consent, unless authorized or required by law or as
                  disclosed to you when the information is collected. The Companies may
                  disclose your personal information to a government institution or agency:
                  (i) that has asserted its lawful authority to obtain the information or
                  where a Company has reasonable grounds to believe the information could be
                  useful in the investigation of unlawful activity; (ii) to comply with a
                  subpoena or warrant or an order made by a court, person or body with
                  jurisdiction to compel the production of information or as required by the
                  Companies to comply with their reporting obligations under applicable law;
                  (iii) to comply with court rules regarding the production of records and
                  information; or (iv) to protect the rights and property of all Companies.
                  If a Company is involved in the sale or transfer of some or all of its
                  business, the Company may disclose your personal information in connection
                  with the transfer or sale.
              </p>
              <p><strong>
                  7. How Your Privacy is Protected
              </strong></p>
              <p>
                  The personal information collected about you is retained in confidence and
                  the Companies have adopted reasonable physical, electronic and managerial
                  procedures appropriate to the sensitivity of the data in an effort to
                  safeguard the personal information you provide. Notwithstanding these
                  measures, no collection or transmission of information over the Internet or
                  otherwise can be guaranteed to be 100% secure, and therefore the security
                  of any such information cannot be warranted or guaranteed.
              </p>
              <p><strong>
                  8. Withdrawal of Consent
              </strong></p>
              <p>
                  You may withdraw your consent to the collection, use and disclosure of
                  personal information about you at any time. To do this, please contact the
                  Privacy Officer at the e-mail address listed in this Policy. Withdrawal of
                  your consent to the collection, use and disclosure of personal information
                  may result in you being unable to continue use of the Website or services.
              </p>
              <p><strong>
                  9. Verifying and Amending your Personal Information
              </strong></p>
              <p>
                  Subject to certain exceptions prescribed by law, you will be given
                  reasonable access to your personal information held by the Companies and
                  you may request that your personal information be corrected or updated.
                  Please e-mail the Privacy Officer at the e-mail address listed in this
                  Policy and we will assist you. You may be required to put your request in
                  writing to confirm your request and identity. It is your responsibility to
                  notify us of any changes to your personal information.
              </p>
              <p><strong>
                  10. Retention of Personal Information
              </strong></p>
              <p>
                  Your personal information will be retained in compliance with the
                  Companies’ legal obligations, and only as long as necessary for the
                  purposes for which it was collected.
              </p>
              <p><strong>
                  11. Storage and Location of Information
              </strong></p>
              <p>
                  You agree, acknowledge and consent to the transfer and storage of all
                  personal information collected or used via the Website to Companies, where
                  such information may be kept and may be accessed and used for any of the
                  aforementioned purposes set out in this Policy. Please note that
                  information collected or used via the Website, when and where under the
                  control of Companies will be subject to the legislation of Canada,
                  including legislation regarding the safeguarding of certain personal
                  information, and may be disclosed to judicial and/or administrative
                  authorities of such jurisdiction, where required by law.
              </p>
              <p>
                  The Companies may store and process your personal information collected
                  through the Website on the servers of our third party service providers
                  including Qumu, WebEx, Zoom, Microsoft or, from time to time, in other
                  locations outside Canada that the service providers deems necessary or
                  convenient in order to provide the services including but not limited to
                  United States of America, and Europe. As such, foreign governments, courts,
                  law enforcement or regulatory agencies may be able to access your personal
                  information under foreign laws. The third party service providers are only
                  permitted to store your personal information, and are not otherwise
                  authorized to collect, use or disclose your personal information, except in
                  accordance with the purposes set out in this Policy.
              </p>
              <p>
                  You agree and acknowledge that the Companies have informed you as to the
                  location where your personal information is kept and may be accessed and
                  used as set forth herein above and that you may: (i) consult your personal
                  information or request changes to it; or (ii) obtain information about the
                  Companies’ policies and procedures with respect to the use of service
                  providers or the collection, use, disclosure or storage of personal
                  information by services outside of Canada; by making a written request to
                  Human Contact Inc. / The Merge Portal 1207 King St. West, Toronto, ON, M6K
                  1G2.
              </p>
              <p><strong>
                  12. Privacy Policy Changes and Updates
              </strong></p>
              <p>
                  This Policy may be revised at any time, without notice to you. The
                  Companies may, however, choose to notify you of a change to this Policy,
                  for example by announcing the change on the Website. Your continued use of
                  the Website constitutes your consent to the contents of any updated policy.
              </p>
              <p>
                  Where we make significant changes to this Policy, we will notify you
                  directly of the changes by sending you an email informing you of the
                  changes, and will require your consent prior to the changes coming into
                  effect. Significant changes include using your personal information for a
                  new purpose not anticipated originally or a new disclosure of your personal
                  information to a third party for a purposes other than processing that is
                  integral to the delivery of a service.
              </p>
              <p><strong>
                  13. Role and Responsibilities of Each Company
              </strong></p>
              <p>
                  Each of the Companies is entitled to the benefit of this Policy. The
                  responsibilities of each Company under these Terms of Use are several and
                  the responsibilities of each Company are not joint.
              </p>
              <p><strong>
                  14. Resolving Your Concerns
              </strong></p>
              <p>
                  If you would like more information about this Policy or the personal
                  information collection, use and disclosure policies and practices of the
                  Companies or their service providers, please contact the Privacy Officer at
              the following email address:    <a href="mailto:contact@humancontact.com">contact@humancontact.com</a>
              </p>
              <p>
                  Please let us know your questions or concerns and we will do our best to
                  help you.
              </p>
            </div>
          )
      
    } else {
        return (
            <div className={"text-page" + (window.hc.device.isMobile ? ' ismobile' : '')}>
                <Helmet>
                    <title>HUMANCONTACT | Politique de confidentialité</title>
                    <meta name="HUMANCONTACT" content="Politique de confidentialité" />
                </Helmet>
        
                <h1>
                    HUMANCONTACT WEBSITE
                </h1>
                <h2>
                    Privacy Policy
                </h2>
                <h2>
                    FRENCH
                </h2>
                <h2>
                    V03 – September 2, 2020
                </h2>
                <p>
                    Human Contact Inc. / The Merge Portal Inc.  (« Merge » ou « nous »), qui exploite le site Web www.humancontact.com (le « Site Web ») pour son compte et pour le compte de Human Contact Inc. / The Merge Portal Inc.  (chacune une « Société » et collectivement les « Sociétés »), tient à protéger les renseignements personnels que vous pouvez lui communiquer ou qui peuvent être obtenus lorsque vous utilisez le Site Web ou lorsque vous lisez les courriels que l’une ou l’autre des Sociétés vous envoient. La présente Politique de confidentialité (la « Politique ») a pour but : i) de vous informer des pratiques et politiques des Sociétés en matière de confidentialité, y compris les raisons pour lesquelles vos renseignements personnels sont recueillis, les méthodes employées pour les recueillir et l’usage qui en est fait, et les parties auxquelles nous pourrions les transmettre et ii) de vous indiquer à qui vous pouvez vous adresser si vous avez des questions ou préoccupations concernant les pratiques des Sociétés. Il est possible d’avoir accès à la présente Politique au moyen du Site Web.
                </p>
                <p>
                    Le présent Site Web est à l’usage exclusif des résidents du Canada. En utilisant le Site Web, vous consentez à la collecte, au stockage, à l’utilisation, à la communication et à la conservation de vos renseignements personnels conformément à la présente Politique. Si vous êtes en désaccord avec la présente Politique, vous ne devriez pas visiter ou utiliser le Site Web.
                </p>
                <p><strong>
                    Aperçu de nos pratiques en matière de protection de la vie privée
                    </strong></p>
                <p>
                    Voici un résumé de nos pratiques en matière de protection de la vie privée. En vertu de la présente Politique, les Sociétés :
                </p>
                <ol type="a">
                    <li>
                        <p>
                            exigent que vous confirmiez avoir atteint l’âge de la majorité dans votre province de résidence, ou que vous avez l’autorisation de votre tuteur qui a l’âge de la majorité dans votre province de résidence ;                      </p>
                    </li>
                    <li>
                        <p>
                            peuvent recueillir des données par l’intermédiaire de votre utilisation du Site Web, de votre adresse IP, des témoins sur le Site Web et des pixels espions dans les courriels; 
                        </p>
                    </li>
                    <li>
                        <p>
                            recueillent des renseignements non nominatifs sur les utilisateurs du Site Web (comme la date et l’heure à laquelle vous accédez au Site Web);
                        </p>
                    </li>
                    <li>
                        <p>
                            communiquent ces renseignements avec leurs employés, mandataires, fournisseurs de services et représentants légaux autorisés, et peuvent aussi transmettre ces renseignements aux tiers auxquels nous faisons appel pour fournir des produits et des services en notre nom; 
                        </p>
                    </li>
                    <li>
                        <p>
                            peuvent utiliser les renseignements recueillis par une Société et les divulguer à l’autre Société conformément à la présente Politique;
                        </p>
                    </li>
                    <li>
                        <p>
                            sous réserve des points d) et e), ne communiquent pas vos renseignements personnels à des tiers sans votre consentement, à moins que la loi l’autorise ou l’exige ou conformément aux indications qui vous ont été fournies au moment de la collecte des renseignements; 
                        </p>
                    </li>
                    <li>
                        <p>
                            recueillent et utilisent ces renseignements pour s’assurer que le destinataire de vos courriels et vous avez l’âge légal pour acheter des produits du tabac dans votre province de résidence, pour respecter les lois et les règlements applicables, pour administrer le Site Web, pour protéger les Sociétés et les utilisateurs du Site Web contre la fraude, pour confirmer les renseignements personnels que vous avez fournis à nos Représentants des ventes afin de vérifier leur exactitude et pour communiquer avec vous;
                        </p>
                    </li>
                    <li>
                        <p>
                            stockent vos renseignements personnels avec Human Contact Inc. ou le serveur The Merge Portal AWS, et peuvent stocker et traiter vos renseignements personnels recueillis par l’intermédiaire du Site Web sur les serveurs de nos fournisseurs de services, y compris Qumu, WebEx, Zoom, Microsoft; cependant, les fournisseurs de services ont seulement la permission de stocker vos renseignements personnels et ne sont par ailleurs pas autorisés à les recueillir, à les utiliser ou à les divulguer, sauf aux fins énoncées dans la présente Politique;
                        </p>
                    </li>
                    <li>
                        <p>
                            vous permettent de retirer votre consentement à la collecte, à l’utilisation et à la divulgation de vos renseignements personnels en tout temps;
                        </p>
                    </li>
                    <li>
                        <p>
                            atténuent le risque et les répercussions des préjudices potentiels découlant de la divulgation des renseignements personnels en adoptant des procédures physiques, électroniques et de gestion raisonnables qui conviennent à la nature sensible des données dans le but de protéger les renseignements personnels que vous nous fournissez. Malgré ces mesures, il demeure un risque résiduel de perte, d’accès non autorisé ou de divulgation non autorisée de vos renseignements qui pourrait vous causer un préjudice financier ou autre.
                        </p>
                    </li>
                </ol>
                <p>
                    Des renseignements supplémentaires sur nos pratiques en matière de protection de la vie privée se trouvent ci-dessous.              </p>
                <p><strong>
                    1. Renseignements personnels recueillis par les Sociétés
                </strong></p>
                <p>
                    Avant votre premier accès au Site Web, les Sociétés vous fourniront un nom d’utilisateur et un mot de passe. Lorsque vous ouvrirez une session sur le Site Web, il faudra confirmer que vous avez atteint l’âge de la majorité dans votre province de résidence et que vous êtes un invité, employée, client, partenaire, ou actionnaire des Sociétés. Une fois la connexion établie, vous aurez accès à un profil rempli par les Sociétés en fonction des renseignements que vous leur avez fournis par le passé lorsque vous avez consulté ou utilisé d’autres sites Web des Sociétés. Les renseignements personnels chargés automatiquement dans votre profil peuvent comprendre notamment votre nom, votre adresse, votre adresse de facturation et vos coordonnées. Les Sociétés peuvent dans certains cas vous demander des renseignements supplémentaires après l’inscription initiale, notamment votre adresse courriel.
                </p>
                <p>
                    Les Sociétés peuvent également recueillir les données sur votre utilisation du Site Web et votre adresse IP (protocole Internet), et d’autres renseignements similaires que vous fournissez aux Sociétés par le Site Web, y compris des renseignements recueillis au moyen de témoins dans votre navigateur. Nous pouvons également recueillir des renseignements concernant les courriels que les Sociétés vous envoient, comme le moment auquel vous ouvrez le courriel, au moyen de gifs à pixel unique (aussi appelés pixels invisibles) ou d’autres technologies de traçage.
                </p>
                <p>
                    Lorsque vous nous communiquez vos renseignements personnels, vous devriez faire preuve de discernement et ne pas communiquer des renseignements sensibles ou confidentiels que vous souhaitez garder privés ou qui vous obligeraient à nous imposer des obligations particulières en matière de sécurité ou de protection des données.
                </p>
                <p><strong>
                    2. Renseignements non identifiables automatiquement recueillis
                </strong></p>
                <p>
                    Le Site Web peut recueillir automatiquement certains renseignements non identifiables concernant les utilisateurs du Site Web, comme la date et l’heure auxquelles vous visitez le Site Web, le consentement aux modalités d’utilisation du Site Web, l’adresse Internet du Site Web sur lequel un hyperlien vous a mené directement au Site Web, le système d’exploitation que vous utilisez, les parties du Site Web que vous visitez, les pages et les images du Site Web que vous visionnez, et le contenu que vous téléchargez à partir du Site Web. Les Sociétés peuvent recueillir, utiliser, transférer ou communiquer de l’information globale et/ou des renseignements non personnels.
                </p>
                <p><strong>
                    3. Témoins
                </strong></p>
                <p>
                Le Site Web utilise des témoins ou « cookies », c’est-à-dire des éléments d’information qui sont installés sur l’ordinateur de l’utilisateur du Site Web afin de permettre au Site Web de reconnaître l’utilisateur au moment de visites ultérieures. Le Site Web devient ainsi plus pratique et utile pour cet utilisateur. Les renseignements communiqués au Site Web au moyen des témoins servent, par exemple, à vous reconnaître comme utilisateur précédent du Site Web, à suivre vos activités sur le Site Web afin de répondre à vos besoins, à vous offrir un contenu et des renseignements personnalisés sur les pages du Site Web et à faciliter d’autres manières votre visite du Site Web. Vous pouvez choisir de refuser les témoins si votre navigateur le permet, mais il se peut que votre utilisation du Site Web et la possibilité d’accéder à certaines de ses options en soient entravées.              </p>
                <p><strong>
                    4. Pixels invisibles
                </strong></p>
                <p>
                    Les courriels que nous vous envoyons pourraient également contenir des pixels invisibles. Les pixels invisibles permettent aux Sociétés de recueillir des renseignements sur le moment auquel le destinataire ouvre les courriels qui lui ont été envoyés, les parties du courriel qu’il revoit, son adresse IP, son type de navigateur ou type de client de messagerie et d’autres détails similaires. Certains de ces renseignements peuvent être mis en corrélation avec les adresses de courriel des destinataires. Les Sociétés utilisent ces renseignements pour étudier la réaction des destinataires aux courriels qui leur sont transmis.
                </p>
                <p><strong>
                    5. Fins de la collecte et de l’utilisation de vos renseignements personnels
                </strong></p>
                <p>
                    Les renseignements personnels que vous nous donnez pendant l’inscription ou qui sont recueillis lorsque vous utilisez le Site Web ou des courriels que les Sociétés vous envoient sont recueillis et utilisés aux fins suivantes :
                </p>
                <ol type="i">
                    <li>
                        <p>
                            la confirmation que les utilisateurs du Site Web et les destinataires des courriels transmis par les Sociétés ont l’âge de la majorité dans leur province de résidence;
                        </p>
                    </li>
                    <li>
                        <p>
                            l’observation des lois et règlements qui régissent les Sociétés et le respect des limites permises par ces lois et règlements;
                        </p>
                    </li>
                    <li>
                        <p>
                            pour administrer le Site Web, y compris les sections des récompenses, des jeux et des concours, pour soutenir les services offerts sur le Site Web et pour gérer les courriels envoyés par les Sociétés;
                        </p>
                    </li>
                    <li>
                        <p>
                            la protection des Sociétés et des utilisateurs du Site Web contre la fraude;
                        </p>
                    </li>
                    <li>
                        <p>
                            pour vous envoyer des renseignements concernant nos produits, nos activités et les programmes et événements (dans la mesure où vous confirmez que votre adresse courriel est accessible uniquement par des adultes dans votre province de résidence), à moins que vous refusiez de recevoir ces renseignements en communiquant avec nous à
                            <a href="mailto:contact@humancontact.com">
                                contact@humancontact.com
                            </a>
                            ;
                        </p>
                    </li>
                    <li>
                        <p>
                        pour vous envoyer des sondages en ligne;
                        </p>
                    </li>
                    <li>
                        <p>
                        d’autres fins permises par les lois.
                        </p>
                    </li>
                </ol>
                <p>
                    Les renseignements recueillis par l’une des deux Sociétés ou qui lui sont communiqués peuvent être utilisés par l’autre Société conformément à la présente Politique.              </p>
                <p><strong>
                    6. Communication de renseignements personnels
                </strong></p>
                <p>
                    Les Sociétés préserveront la confidentialité des renseignements personnels que vous leur communiquez et ne les communiqueront que conformément à la présente Politique.              </p>
                <p>
                    Les Sociétés ne vendent ni ne louent d’aucune manière à des tiers les renseignements personnels recueillis auprès de vous. Les Sociétés peuvent communiquer des renseignements personnels à leurs salariés, mandataires, fournisseurs de services et représentants légaux autorisés ou les échanger avec eux aux fins décrites dans la présente Politique (notamment MailChimp). Elles peuvent également communiquer vos renseignements personnels à des tiers dont elles retiennent les services pour l’offre de produits et de services en leur nom.
                </p>
                <p>
                    Les Sociétés n’échangeront d’aucune autre manière vos renseignements personnels avec des tiers sans votre consentement, à moins que la loi ne le leur permette ou ne l’exige ou de vous en avoir informé au moment de la collecte des renseignements. Les Sociétés peuvent communiquer vos renseignements personnels à un organisme gouvernemental : i) se prévalant de son autorité reconnue par la loi d’obtenir de tels renseignements, dans un cas où une Société a des motifs raisonnables de croire que les renseignements pourraient servir à une enquête visant des activités illicites, ii) pour se conformer à une sommation, un mandat ou une ordonnance d’un tribunal, d’une personne ou d’un organisme ayant l’autorité d’exiger la production de renseignements ou pour s’acquitter de leurs obligations en matière de communication de l’information prévues dans les lois applicables, iii) pour se conformer aux règles d’un tribunal concernant la production de registres et de renseignements, ou iv) pour protéger les droits et biens des Sociétés. En cas de vente ou de cession par une Société de son entreprise, en totalité ou en partie, elle peut communiquer vos renseignements personnels dans le cadre de la cession ou de la vente.
                </p>
                <p><strong>
                    7. Mesures prises pour protéger la confidentialité
                </strong></p>
                <p>
                    La confidentialité des renseignements personnels recueillis à votre sujet doit être préservée et les Sociétés ont pris des mesures matérielles, électroniques et administratives raisonnables appropriées à la sensibilité des données en vue de protéger les renseignements personnels que vous communiquez. Malgré ces mesures, la sécurité absolue de la collecte ou de la communication de renseignements sur Internet ou autrement ne peut être garantie et il est donc impossible de confirmer ou de garantir la sécurité de tels renseignements.
                </p>
                <p><strong>
                    8. Retrait de votre consentement
                </strong></p>
                <p>
                    Vous pouvez en tout temps retirer votre consentement à l’égard de la collecte, de l’utilisation et de la communication de renseignements personnels à votre sujet. Veuillez communiquer avec le Responsable de la protection de la vie privée à l’adresse de courriel indiquée dans la présente Politique. Le retrait de votre consentement à l’égard de la collecte, de l’utilisation et de la communication de renseignements personnels pourrait avoir comme résultat de vous empêcher de poursuivre votre utilisation du Site Web ou de services.
                </p>
                <p><strong>
                    9. Vérification et modification de vos renseignements personnels
                </strong></p>
                <p>
                    Sous réserve de certaines exceptions prévues par la loi, les Sociétés vous donneront accès, dans la mesure raisonnable, aux renseignements personnels à votre sujet qu’elles possèdent et vous pourrez demander que des modifications ou mises à jour y soient apportées. Veuillez à cette fin communiquer avec le Responsable de la protection de la vie privée à l’adresse de courriel indiquée dans la présente Politique. Nous pourrions vous demander de présenter une demande écrite afin de pouvoir confirmer votre demande et votre identité. Il vous incombe de nous aviser de tout changement apporté à vos renseignements personnels.

                </p>
                <p><strong>
                    10. Conservation de vos renseignements personnels
                </strong></p>
                <p>
                    Vos renseignements personnels seront conservés conformément aux obligations juridiques des Sociétés et seulement pendant qu’il est nécessaire de les conserver aux fins desquelles ils ont été recueillis.
                </p>
                <p><strong>
                    11. Lieu de stockage des renseignements
                </strong></p>
                <p>
                    Vous reconnaissez et acceptez le transfert de tous les renseignements personnels recueillis ou utilisés par l’intermédiaire du Site Web aux Sociétés, où ces renseignements peuvent être conservés, consultés et utilisés à l’une des fins susmentionnées dans la présente Politique. Veuillez noter que les renseignements recueillis ou utilisés par l’intermédiaire du Site Web, lorsqu’ils sont sous le contrôle des Sociétés, y compris les lois concernant la protection de certains renseignements personnels, et peuvent être divulgués à des autorités judiciaires ou administratives de ce territoire si la loi l’exige.               </p>
                <p>
                    Les Sociétés pourraient stocker et traiter les renseignements personnels à votre sujet qu’elles recueillent au moyen du Site Web sur les serveurs de nos tiers fournisseurs de service, y compris Qumu, WebEx, Zoom, Microsoft ou, de temps à autre, à d’autres endroits situés à l’extérieur du Canada que les fournisseurs de services jugent nécessaires ou pratiques afin de fournir les services, y compris, sans s’y limiter, aux États-Unis et en Europe. Ainsi, il est possible que des États, tribunaux ou organismes d’application de la loi ou de réglementation étrangers soient en mesure de consulter vos renseignements personnels selon les dispositions de lois étrangères. Les tiers fournisseurs de service ne sont autorisés qu’à stocker vos renseignements personnels et ne sont nullement autorisés à les recueillir, à les utiliser ou à les communiquer, sauf conformément aux fins décrites dans la présente Politique.
                </p>
                <p>
                    Vous acceptez et reconnaissez que les Sociétés vous ont informé du lieu où vos renseignements personnels sont conservés et peuvent être consultés et utilisés, tel qu’il est indiqué ci-dessus, et que vous pouvez : i) consulter vos renseignements personnels ou soumettre une demande de modifications de ceux-ci ou ii) obtenir des renseignements sur les politiques et les procédures des Sociétés qui concernent le recours à des fournisseurs de services ou la collecte, l’utilisation, la divulgation ou le stockage de renseignements personnels par des services situés à l’extérieur du Canada, en soumettant une demande écrite à Human Contact Inc. / The Merge Portal 1207 King St. West, Toronto, ON, M6K 1G2.
                </p>
                <p><strong>
                    12. Modification et mise à jour de la Politique de confidentialité
                </strong></p>
                <p>
                    Nous pouvons réviser la présente Politique en tout temps sans vous donner de préavis. Cependant, les Sociétés peuvent décider de vous aviser d’une modification apportée à la présente Politique, par exemple en l’annonçant sur le Site Web. En continuant d’utiliser le Site Web, vous êtes réputé avoir accepté le contenu de toute politique mise à jour.
                </p>
                <p>
                    Si nous apportons des modifications importantes à la présente Politique, nous vous avisons directement des modifications par courriel et nous vous demandons votre consentement avant leur entrée en vigueur. Les modifications importantes comprennent l’utilisation de vos renseignements personnels à une nouvelle fin non prévue à l’origine ou une nouvelle divulgation de vos renseignements personnels à un tiers à des fins autres que le traitement essentiel à la prestation d’un service.
                </p>
                <p><strong>
                    13. Rôle et responsabilités de chaque Société
                </strong></p>
                <p>
                    Chacune des Sociétés a droit aux bénéfices qui découlent de la présente Politique. Les responsabilités de chacune des Sociétés en vertu des Modalités d’utilisation sont solidaires mais la responsabilité de chacune des sociétés n’est pas conjointe.  
                </p>
                <p><strong>
                    14. Réponses à vos questions
                </strong></p>
                <p>
                    Si vous souhaitez obtenir de plus amples renseignements sur la présente Politique ou sur les pratiques de collecte, d’utilisation et de communication de renseignements personnels des Sociétés ou de leurs fournisseurs de services, veuillez communiquer avec le Responsable de la protection de la vie privée à l’adresse de courriel suivante :
                    <a href="mailto:contact@humancontact.com">contact@humancontact.com</a>
                </p>
                <p>
                    Nous ferons de notre mieux pour répondre à vos questions ou préoccupations.
                </p>
            </div>
          )      
    }
  }
}

export default withTranslation()(Terms);
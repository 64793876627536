// screen #0: Landing
// screen #1: About
// screen #2: Work
// screen #3: Team
// screen #4: Contact

// screen #5: Project List
// screen #6: Project Details

export const breakpoints = {
  xl: 1200,
  l: 950,
  m: 740,
  m2: 600,
  s: 500
}

export const backgroundpositions = {
  0: {
    left:  { width: '0', left: '0' }, // left backgroundcover
    right: { width: '0', right: '0' } // right backgroundcover
  },
  1: {
    left:  { width: '56vw', left: '0' },
    right: { width: '0', right: '0' }
  },
  2: {
    left:  { width: '0', left: '0' },
    right: { width: '0', right: '0' }
  },
  3: {
    left:  { width: '0', left: '0' },
    right: { width: '50vw', right: '50vw' }
  },
  4: {
    left:  { width: '0', left: 'unset' },
    right: { width: '0', right: '0' }
  },
  5: {
    left:  { width: '0', left: '0' },
    right: { width: '0', right: '0' }
  }
};

import React from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';

class Careers extends React.Component {

  state = {
    jobs: this.props.i18n.language === 'en' ? window.hc.content.main.jobs : window.hc.content.main.jobs_fr,
    jobs_page: {
      title: this.props.i18n.language === 'en' ? window.hc.content.main.job_page_title : window.hc.content.main.job_page_title_fr,
      description: this.props.i18n.language === 'en' ? window.hc.content.main.job_page_description : window.hc.content.main.job_page_description_fr
    }
  }

  componentDidMount() {
    window.hc.screen.updateIndex(4);

    gsap.fromTo('.careerscontainer', { opacity: 0 }, { opacity: 1 })

    const toggleContent = () => {
      let isEnglish = this.props.i18n.language === 'en';
      let jobs = isEnglish ? window.hc.content.main.jobs : window.hc.content.main.jobs_fr;
      let jobsPage = isEnglish ? {
        title: window.hc.content.main.job_page_title,
        description: window.hc.content.main.job_page_description
      } : {
        title: window.hc.content.main.job_page_title_fr,
        description: window.hc.content.main.job_page_description_fr
      }
      this.setState({ jobs, jobs_page: {...jobsPage} })
    }

    toggleContent();

    this.props.i18n.on('languageChanged', (lng) => {
      toggleContent();
    })
  }

  render() {

    return (
      <div className="careerscontainer">

        <Helmet>
          <title>HUMANCONTACT | Careers</title>
          <meta name="HUMANCONTACT" content="Contact Us | Email: contact@humancontact.com | Phone: 416-361-0113" />
        </Helmet>

        <h1>{this.state.jobs_page.title}</h1>

        <div id="careerscontent">
          <div>
            <p>{this.state.jobs_page.description}</p>
          </div>
          <div id="postings">
            {this.state.jobs && this.state.jobs.map(job => (
              <div className="posting" key={job.job_title}>
                <h3>{job.job_title}</h3>
                <h4>{job.job_duration}</h4>
                <a href={job.details_url} target="_blank" rel="noopener noreferrer">
                  <div className="details">{this.props.t('DETAILS')}</div>
                </a>
              </div>
            ))}
          </div>
        </div>

      </div>
    )
  }
}

export default withTranslation()(Careers);
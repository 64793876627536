import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class BDO extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div id="project-bdo" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img src={require('assets/images/projects/bdo/1_LandingPage_Illustrations.jpg')} alt="banner" />
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img src={require('assets/images/projects/bdo/2_BDO_Logo.png')} alt="bdo logo" />
          <p>
            {i18n.language === "en" ? "BDO Canada's Partners provide expertise and services to clients across multiple industries. Their thought leadership is showcased through articles, videos, and other media to educate and inform their clients. Over the years, we have worked on a variety of these campaigns." : "Les partenaires de BDO Canada offrent leur expertise et leurs services à des clients de multiples secteurs. Leurs idées éclairées sont mises en avant grâce à des articles, des vidéos et d'autres médias qui permettent d'éduquer et d'informer leurs clients. Au fil des ans, nous avons travaillé sur une variété de ces campagnes."}
          </p>
        </div>

        <div className="laptop projectsectionhidden">
          <div className="indicator">{t('VC Pitch Day Website')}</div>
          <img src={require('assets/images/projects/bdo/3_MacPro_Transparent.png')} alt="laptop mockup" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Website Wireframe')}</div>
        </div>
        <div className="wireframe projectsectionhidden">
          <img src={require('assets/images/projects/bdo/4_WebsiteWireframe.png')} alt="website wireframe" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Sandy Bay Video')}</div>
        </div>
        <div className="productvideo projectsectionhidden">
          <video width="100%" height="auto" controls={true} autoPlay={true} muted={true} poster={require('assets/images/projects/_covers/bdo-sandybay.jpg')}>
            <source src="https://player.vimeo.com/external/378609003.hd.mp4?s=0732a2e1a0c7491acc1bc2ad61b02337d164ab02&profile_id=175" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Infographics')}</div>
        </div>
        <div className="photo1 projectsectionhidden">
          <img src={require('assets/images/projects/bdo/6_MegaTrend_Infographic.png')} alt="project infographic" />
        </div>

        <div className="photo2 projectsectionhidden">
          <img src={require('assets/images/projects/bdo/7_Mining_Infographic.png')} alt="project infographic mining" />
        </div>

        <div className="photo3 projectsectionhidden">
          <img src={require('assets/images/projects/bdo/8_IndustryCamp.jpg')} alt="industry camp" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Animated Report Summary')}</div>
        </div>
        <div className="productvideo productvideo2 projectsectionhidden">
          <video width="100%" height="auto" controls={true} autoPlay={true} muted={true} poster={require('assets/images/projects/_covers/bdo-report.jpg')}>
            <source src="https://player.vimeo.com/external/379059683.hd.mp4?s=5ae1971410fc9c95d7ec8293d05bcf7b90eb2e67&profile_id=175" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

      </div>
    )
  }
}

export default withTranslation()(BDO);

import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class KFC extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render_kfcicons() {

    const icons = [
      require('assets/images/projects/kfc/icons/1.png'),
      require('assets/images/projects/kfc/icons/2.png'),
      require('assets/images/projects/kfc/icons/3.png'),
      require('assets/images/projects/kfc/icons/4.png'),
      require('assets/images/projects/kfc/icons/5.png'),
      require('assets/images/projects/kfc/icons/6.png'),
      require('assets/images/projects/kfc/icons/7.png'),
      require('assets/images/projects/kfc/icons/8.png')
    ]

    return (
      <div className="kfc-icons">
        { icons.map((icon, i) => <img src={icon} key={i} alt="kfc icon" />) }
      </div>
    )
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-kfc" className="projectdetailcontainer">
        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <img id="mainlogo" src={require('assets/images/projects/kfc/logo1' + french_version + '.png')} alt="logo" />
          <div className="container">
            <img src={require('assets/images/projects/kfc/banner-asset-1.png')} alt="banner 1" />
            <img src={require('assets/images/projects/kfc/banner-asset-3.png')} alt="banner 2" />
            <img src={require('assets/images/projects/kfc/banner-asset-2.png')} alt="banner 3" />
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img src={require('assets/images/projects/kfc/logo2' + french_version + '.png')} alt="kfc logo" />
          <p>
            {i18n.language === 'en' ? "The 2019 RGM Conference was the first-of-its-kind for KFC Canada. Over 800 guests, from all across the business, came to downtown Toronto over 3 days for business sessions, networking, celebration, a 30+ vendor Trade Show, and workshops. We were involved in all facets of the event, from overall project management and branding to content creation." : "La conférence des RGM de 2019 était la première en son genre pour KFC Canada. Plus de 800 invités des différents secteurs de l'entreprise se sont rendus au centre-ville de Toronto pour plus de 3 jours de sessions sur les affaires, pour réseauter, pour participer à des célébrations, des ateliers et à une foire commerciale avec plus de 30 vendeurs. Human Contact était responsable de toutes les facettes de l'événement : de la gestion complète du projet à la création de l'identité de marque et du contenu."}
          </p>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .productvideo').scrollIntoView({
            behavior: 'smooth'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
            <h4>{t('Event Management')}</h4>
            <h4>{t('Experiential')}</h4>
          </div>
        </div>

        <div className="productvideo projectsectionhidden">

          <video width="100%" height="auto" controls={false} autoPlay={true} muted={true} poster={require('assets/images/projects/kfc/fallback.jpg')}>
            <source src={require('assets/images/projects/kfc/kfc.mp4')} type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="wireframe projectsectionhidden">
          <div className="indicator">{t('Wireframe')}</div>
          <img src={require('assets/images/projects/kruger/wireframe-skeleton.png')} alt="gallery item" />
        </div>

        <div className="laptop projectsectionhidden">
          <div className="indicator">{t('Web')}</div>
          <img src={require('assets/images/projects/kfc/laptop.png')} alt="gallery item" />
        </div>

        <div className="tablets projectsectionhidden">
          <img src={require('assets/images/projects/kfc/tablet.png')} alt="gallery item" />
        </div>

        {this.render_kfcicons()}

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Snapchat Filter')}</div>
        </div>

        <div className="snapchat projectsectionhidden">
          <img src={require('assets/images/projects/kfc/snapchat-phone.png')} alt="gallery item" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Signage')}</div>
        </div>
        <div className="gallery-item projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general1.png')} alt="gallery item" />
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Gallery')}</div>
        </div>
        <div className="gallery-item projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general2.jpg')} alt="gallery item" />
        </div>
        <div className="gallery-item split projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general3-1.jpg')} alt="gallery item" />
          <img src={require('assets/images/projects/kfc/general3-2.jpg')} alt="gallery item" />
        </div>
        <div className="gallery-item projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general4.jpg')} alt="gallery item" />
        </div>
        <div className="gallery-item split projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general5-1.jpg')} alt="gallery item" />
          <img src={require('assets/images/projects/kfc/general5-2.jpg')} alt="gallery item" />
        </div>
        <div className="gallery-item projectsectionhidden">
          <img src={require('assets/images/projects/kfc/general6.jpg')} alt="gallery item" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(KFC);

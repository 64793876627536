class Renderer{
  constructor() {

    this.methods = []
    this.key = 0;

    requestAnimationFrame(() => { this.loop() })

  }

  loop() {

    requestAnimationFrame(() => { this.loop() })

    this.methods.forEach(item => {
      item.method()
    })

  }

  add(method) {

    const { key } = this;

    this.methods.push({
      key: this.key,
      method: method
    });

    this.key++;

    return key;

  }

  remove(key) {

    const removed = this.methods.splice(this.methods.findIndex((i) => {
      return i.key === key
    }), 1);

    return removed.key;

  }

  destroy() {
    this.methods = []

    return this.methods;
  }

}

export default Renderer;

import React from 'react';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';

import $ from 'jquery';

class HTTP404 extends React.Component {

  state = {
    timer: 6
  }

  componentDidMount() {

    if(document.querySelector('body')) document.querySelector('body').style.backgroundColor = '#202020'
    if(document.querySelector('.backgroundscene')) document.querySelector('.backgroundscene').style.display = 'none'

    if(document.querySelector('.loader')) document.querySelector('.loader').style.display = 'none'
    if(document.querySelector('.menus #menuitems')) document.querySelector('.menus #menuitems').style.display = 'none'
    if(document.querySelector('.menus #hc-logo')) document.querySelector('.menus #hc-logo').onclick = () => {
      window.location.href = '/'
    }


    if(window.hc.device.ie || window.hc.device.edge) $('#http404 h1').addClass('no-dot')


    setInterval(() => {

      if(this.state.timer === 0) window.location.href = '/'
      else
        this.setState({ timer: this.state.timer - 1 })

    }, 1000)

  }

  onLoad() {
    gsap.to(document.querySelector('#http404'), 0.4, {
      opacity: 1,
      marginTop: 0,
      delay: 0.4
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div id="http404">
        <h1>404</h1>

        <div className="content">
          <h3>{t('It could be a case of human error,')}<br/></h3>
          <h4>{t('You will be redirected in')} {this.state.timer}s...</h4>
        </div>

        <img src={require('assets/images/404-nobg-web.png')} alt='404 background objects' onLoad={() => { this.onLoad() }} />

      </div>
    )
  }
}

export default withTranslation()(HTTP404);

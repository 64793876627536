import React from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';
import Scene from 'screens/Scene';

let projectdata = []

class Work extends React.Component{

  state = {
    rotating: false
  }

  componentDidMount() {

    projectdata = Object.values(window.hc.content.projects).sort(function(a,b){
      if(a.order < b.order) return -1;
      else if(a.order > b.order) return 1;
      else return 0;
    });

    window.hc.screen.updateIndex(2)

    // animate
    setTimeout(() => {

      gsap.to(document.querySelector('.workcontainer .main h1'), 0.6, { opacity: 1, transform: 'translateY(-50%)' })

      if(document.querySelector('.workcontainer .main .openprojects'))
        document.querySelector('.workcontainer .main .openprojects').classList.add('active')

    }, 1000)


    this.onMouseMove = (e) => {

      if(this.openingProject) return

      const cursor = document.querySelector('#workcursor')

      cursor.style.left = e.clientX + 'px'
      cursor.style.top = e.clientY + 'px'

    }

    window.addEventListener('mousemove', this.onMouseMove)

    window.addEventListener('scroll', (e) => {

      // project reveal
      document.querySelectorAll('.projectlist .project').forEach(project => {

        const bounds = project.getBoundingClientRect()

        bounds.top > -400 && bounds.top < 1000 && bounds.bottom > 0 ?
          project.classList.add('active') :
          project.classList.remove('active')

      })

      // scrollbar
      const scrollEl = document.querySelector('#workscrollbar')
      const contentContainer = document.querySelector('.workcontainer')

      if(contentContainer) {
        const contentHeight = Math.max( contentContainer.scrollHeight, contentContainer.offsetHeight, contentContainer.clientHeight, contentContainer.scrollHeight, contentContainer.offsetHeight ) - window.innerHeight

        const offsetPosY = ( window.scrollY / contentHeight ) * 100

        window.scrollY > window.innerHeight * 0.8 ? scrollEl.classList.add('visible') : scrollEl.classList.remove('visible')
        scrollEl.style.width = offsetPosY + '%'
      }

    })

    this.diameter = () => {
      const { innerWidth, innerHeight } = window;
      const width = innerWidth > innerHeight ? innerWidth : innerHeight;

      return width * Math.sqrt(2); // circle diameter based on browser size
    }

  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove)
  }

  onHoverViewmore(hovering) {

    const cursor = document.querySelector('#workcursor')

    gsap.to(cursor, 0.4, {
      transform: hovering ? 'scale(1) translate(-50%,-50%)' : 'scale(0) translate(-50%,-50%)'
    })

  }

  openProject(index) {

    const project = projectdata[index]
    const cursor = document.querySelector('#workcursor')

    this.openingProject = true
    cursor.classList.add('lock')

    gsap.to(cursor, 1.2, {
      width: this.diameter() * 2,
      height: this.diameter() * 2
    })

    setTimeout(() => {

      this.props.history.push(`/work/${project.slug}`)

    }, 1200); // delay for cursor animation

  }

  render_projects() {

    const container = projectdata.map((project, index) => {

      const {title, title_fr, client, client_fr, date, date_fr, tagline, tagline_fr, tagline2, tagline2_fr, image, image_fr} = project;

      // horizontal layout
      if(index % 2 === 0)
        return (
          <div className="project horizontal" key={index}>
            <div className="basics">
              <div>
                <h1
                  onMouseEnter={() => { this.onHoverViewmore(true) }}
                  onMouseLeave={() => { this.onHoverViewmore(false) }}
                  onClick={(e) => { e.preventDefault(); this.openProject(index) }}
                  >{this.props.i18n.language === 'en' ? title : title_fr}</h1>
              </div>
              <div className="info">
                <h6>{this.props.i18n.language === 'en' ? date : date_fr}</h6>
                <h6>{this.props.i18n.language === 'en' ? client : client_fr}</h6>
              </div>
            </div>
            <div className="descriptions">
              <h2>{this.props.i18n.language === 'en' ? tagline : tagline_fr}</h2>
              <h5>{this.props.i18n.language === 'en' ? tagline2 : tagline2_fr}</h5>

              <a className="viewmore"
                 href={'/work/' + projectdata[index].slug}
                 onMouseEnter={() => { this.onHoverViewmore(true) }}
                 onMouseLeave={() => { this.onHoverViewmore(false) }}
                 onClick={(e) => { e.preventDefault(); this.openProject(index) }}>{this.props.t('view more')}</a>

            </div>
          </div>
        );

      // vertical layout
      else {
        const image_vert = this.props.i18n.language === 'en' ? image : image_fr;
        return (
          <div className="project vertical" key={index}>
            <div className="basics"
                 onMouseEnter={() => { this.onHoverViewmore(true) }}
                 onMouseLeave={() => { this.onHoverViewmore(false) }}
                 onClick={(e) => { e.preventDefault(); this.openProject(index) }}
                 style={{backgroundImage: "url(" + image_vert + ")"}}
                 >
              <div className="cover"></div>
            </div>
            <div className="descriptions">
              <h1>{this.props.i18n.language === 'en' ? title : title_fr}</h1>
              <h2>{this.props.i18n.language === 'en' ? tagline : tagline_fr}</h2>
              <h5>{this.props.i18n.language === 'en' ? tagline2 : tagline2_fr}</h5>

              <a className="viewmore"
                 href={'/work/' + projectdata[index].slug}
                 onMouseEnter={() => { this.onHoverViewmore(true) }}
                 onMouseLeave={() => { this.onHoverViewmore(false) }}
                 onClick={(e) => { e.preventDefault(); this.openProject(index) }}>{this.props.t('view more')}
                 </a>

            </div>
          </div>
        );
      }

    })

    return container;
  }

  render() {
    const { i18n } = this.props;

    return (
      <div className="workcontainer">

        <Helmet>
          <title>HUMANCONTACT | Work</title>
          <meta name="HUMANCONTACT" content="See our work" />
        </Helmet>

        <Scene />

        <div id="workcursor"></div>

        <div className="main">
          <h1>{i18n.language === 'en' ? window.hc.content.main.tagline : window.hc.content.main.tagline_fr}</h1>
          <div className="openprojects"
               onClick={() => {
                 window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }) }}>
               {i18n.language === 'en' ? window.hc.content.main.button_text : window.hc.content.main.button_text_fr}
           </div>
           <div className="workscenereplacement">
             <img src={require('assets/images/backgrounds/scene-replace.png')} alt="scene replacement" />
           </div>
        </div>

        <div className="projectlist">
          <div id="workscrollbar"></div>
          <div className="content">
            <div>{this.render_projects()}</div>
          </div>
        </div>

      </div>
    )

  }

}

export default withTranslation()(Work);

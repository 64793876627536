import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class Kruger extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-auction" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <video width="100%" height="auto" muted={true} controls={true} loop={true} autoPlay={true} poster={require('assets/images/projects/_covers/merit-main.jpg')}>
            <source src="https://player.vimeo.com/external/383669026.sd.mp4?s=38f9d012f7ef640561411a3d47ceb8693c922f7f&profile_id=165" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>

        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img id="meritlogo" src={require('assets/images/projects/auction/logo.png')} alt="project logo" />
          <p>
            {t('Merit Travel has long supported')} <span>{t('WaterAid’s Tanzania Deliver Life')}</span>{t('program, and wanted to amplify their impact in 2019 with an online auction. We were tasked with consulting on the project, developing the branding and accompanying materials, as well as administering the auction. All told, it raised')}
          </p>
          <img src={require('assets/images/projects/auction/price' + french_version + '.png')} alt="project fundraiser price" />
        </div>


        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Custom Illustrations')}</div>
        </div>

        <div className="illustrations projectsectionhidden">
          <div className="container">
            <img src={require('assets/images/projects/auction/i_mom.png')} alt="project illustration" />
            <img src={require('assets/images/projects/auction/i_kids.png')} alt="project illustration 2" />
          </div>
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('Social Engagement Campaign')}</div>
        </div>
        <div className="tablet-mockup projectsectionhidden">
          <img src={require('assets/images/projects/auction/mockup-ipad.jpg')} alt="project mockup ipad" />
        </div>

        <div className="laptop projectsectionhidden">
          <img src={require('assets/images/projects/auction/mockup-laptop.jpg')} alt="project mockup laptop" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(Kruger);

import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class CarrotRewards extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div id="project-carrot" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <video width="100%" height="auto" controls={true} autoPlay={true} muted={true} poster={require('assets/images/projects/_covers/carrot-feature.jpg')}>
            <source src="https://player.vimeo.com/external/380812338.hd.mp4?s=808ba80870bc35cae0a69c653dd6d5795ae424a2&profile_id=175" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
            <h4>{t('Event Management')}</h4>
            <h4>{t('Experiential')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <p>
            {t('Carrot Rewards 1')}<span>{t('Carrot Rewards 2')}</span>{t('Carrot Rewards 3')}
          </p>
        </div>

        <div className="phone projectsectionhidden">
          <img src={require('assets/images/projects/carrot/phone.jpg')} alt="project banner" />
        </div>

        <div className="photo1 projectsectionhidden">
          <div className="indicator">{t('Printed Infographic & Branded Stickers')}</div>
          <div>
            <img src={require('assets/images/projects/carrot/banner3.png')} alt="project infographic" />
            <img src={require('assets/images/projects/carrot/banner2.png')} alt="project stickers" />
          </div>
        </div>


        <div className="indicatorcontainer projectsectionhidden" style={{ backgroundColor: '#F0F5F8', margin: 0, padding: '40px 0' }}>
          <div className="indicator">{t('Explainer Video')}</div>
        </div>
        <div className="productvideo projectsectionhidden">
          <video width="100%" height="auto" controls={true} autoPlay={true} muted={true} poster={require('assets/images/projects/_covers/carrot-explainer.jpg')}>
            <source src="https://player.vimeo.com/external/380812478.hd.mp4?s=785d1e09ef13e7c7f262e63ab628ca80d2461ef9&profile_id=175" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="photo2 projectsectionhidden">
          <div className="indicator">{t('Launch Presentation')}</div>
          <img src={require('assets/images/projects/carrot/laptop.jpg')} alt="project laptop" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(CarrotRewards);

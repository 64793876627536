import React from 'react';
import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import $ from 'jquery';

class About extends React.Component {

  state = {
    option: 0,
    hovering: false
  }

  componentDidMount() {

    window.hc.screen.updateIndex(1);

    // change description / logos
    setInterval(() => {

      if(!this.state.hovering)
        this.setState({ option: this.state.option === 0 ? 1 : 0 })

    }, 6000)

    // prevent panel change if hovering
    $('#aboutcontent').hover(
      () => { this.setState({ hovering: true }) },
      () => { this.setState({ hovering: false }) }
    )

    this.animatePanel();

  }

  componentDidUpdate(prevProps, prevState) {

    if(this.state.option !== prevState.option)
      this.animatePanel()

  }

  animatePanel() {

    // description
    if(this.state.option === 0)
      gsap.fromTo(document.querySelector('#aboutcontent #description'), { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 0.6 });

    // logos
    if(this.state.option === 1) {
      const grid = $('#logos #logoscontainer');
      const images = grid.find('.logo');
      const columns = grid.css('grid-template-columns').split(' ').length;

      Array.from(images).forEach((image,i) => {

        const x = i % columns;
        const y = Math.floor(i/columns);

        gsap.to(image, 1.2, { opacity: 1, delay: (x+y)/20 });
        gsap.to(image, 0.4, { transform: 'translateY(0)', delay: (x+y)/20 });

      })
    }

  }

  render() {

    const { option } = this.state;
    const {t, i18n} = this.props;
    const logos = i18n.language === 'en' ? window.hc.content.main.client_logos : window.hc.content.main.client_logos_fr;

    return (
      <div id="aboutcontainer">
        <Helmet>
          <title>HUMANCONTACT | About</title>
          <meta name="HUMANCONTACT" content="About HumanContact." />
        </Helmet>

        <div id="aboutcontent">

          {option === 0 ?
            <div id="description" dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? window.hc.content.main.description : window.hc.content.main.description_fr}}></div> :
            <div id="logos">
              <div id="logoscontainer">
                {logos && logos.map((item,i) =>
                  <div className="logo" key={item.logo_image}><img src={item.logo_image} alt={'logo ' + i} style={{ transform: "scale(" + item.scale + ")" }} /></div>
                )}
              </div>
            </div>
          }

          <div id="aboutnavigation">
            <div id="line"></div>
            <div id="options">
              <h4 data-index="1" style={{ opacity: option === 0 ? 1 : 0.4 }} onClick={() => { this.setState({ option: 0 }) }}>{t('About Us')}</h4>
              <h4 data-index="2" style={{ opacity: option === 1 ? 1 : 0.4 }} onClick={() => { this.setState({ option: 1 }) }}>{t('Our Clients')}</h4>
            </div>
          </div>

        </div>
        <div id="slogan">
          <h1 dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? window.hc.content.main.message : window.hc.content.main.message_fr}}></h1>
        </div>
      </div>
    )
  }
}

export default withTranslation()(About);

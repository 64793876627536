import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import gsap from 'gsap';

class Landing extends React.Component{

  componentDidMount() {

    window.hc.screen.updateIndex(0)

    setTimeout(() => {
      if(document.querySelector('#landingbackground #landingcover')) document.querySelector('#landingbackground #landingcover').classList.add('loaded')
    }, 1000)

  }

  render() {
    const { t } = this.props;
    const fallbackImage = <img src={window.hc.content.main.fallback_image} title="Your browser does not support the <video> tag" alt="fallback background" />

    return (
        <div>

          <Helmet>
            <title>HUMANCONTACT</title>
            <meta name="HUMANCONTACT" content="We are the most versatile agency of our kind. | Welcome" />
          </Helmet>

          <div id="landingbackground">
            <div id="landingcover" style={{ display: window.hc.appLoaded ? 'none' : 'block' }}></div>

            { window.hc.device.isMobile ?
              fallbackImage :
              <video autoPlay={true} loop={true} muted={true}>
                <source src={window.hc.content.main.hc_video} type="video/mp4" />
                {t('Your browser does not support the video tag.')}
                {fallbackImage}
              </video>
            }

            <img id="img-logo"
                 src={window.hc.content.main.hc_logo}
                 style={{ opacity: 0 }}
                 onLoad={() => { gsap.to(document.querySelector('#img-logo'), 0.4, { opacity: 1, delay: 0.4 }) }}
                 alt="humancontact logo" />
          </div>

        </div>
    )
  }

}

export default withTranslation()(Landing);

import React from 'react';
import feather from 'feather-icons';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';

class ButtonCloseProject extends React.Component {

  componentDidMount() {
    feather.replace()

    setTimeout(() => {
      $('.projectclosebutton').removeClass('visible')
    }, 2500)

    document.addEventListener('mousemove', (e) => {

      const mouseNearTop = e.clientY < (window.innerHeight * 0.1)
      const closeProjectButton = $('.projectclosebutton')

      mouseNearTop ? closeProjectButton.addClass('visible') : closeProjectButton.removeClass('visible')

    })
  }

  render() {
    const { t } = this.props;
    return (
      <div id="projectclosebuttoncontainer">
        <div className="projectclosebutton visible" onClick={() => { window.history.back(); }}>
          <h4>{t('CLOSE')}</h4>
          <i data-feather="x" color="#111111"></i>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ButtonCloseProject);

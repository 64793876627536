import React from 'react';
import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import { withTranslation } from 'react-i18next';
import gsap from 'gsap';
import 'slick-carousel';
import { Helmet } from 'react-helmet';
import feather from 'feather-icons';

class Team extends React.Component {

  state = {
    human: undefined,

    teamGridView: window.innerWidth > 950,
    teamGridHovering: false
  }

  componentDidMount() {

    window.hc.screen.updateIndex(3)

    const container = $('.teamcontainer');

    this.wireframe = {
      container: container,
      containerContent: container.find('.descriptioncontainer'),
      slider: container.find('.photos'),
      description: {
        container: container.find('.descriptions'),
        title: container.find('.descriptions h2'),
        name: container.find('.descriptions h1'),
        summary: container.find('.descriptions .summary')
      },
      photoCover: container.find('.portraits .cover'),
      gridContainer: container.find('.teamgrid')
    }

    this.personIntervalTime = 10000
    this.personInterval = setInterval(() => { this.updateContent() }, this.personIntervalTime)

    this.setState({ human: window.hc.content.main.people[0] })

    // feather icons
    feather.replace()

    // initial animation
    this.animate()

    // always use slider for tablet
    window.addEventListener('resize', () => {
      if(this.state.teamGridView && window.innerWidth < 950) this.toggleLayout()
    })

    window.innerWidth < 950 ? this.initSlider() : Scrollbar.initAll() // initialize grid or slider

  }

  animate() {

    gsap.to(document.querySelector('.teamcontainer .portraits'), 0.6, {
      opacity: 1,
      delay: 0.4
    })

    // unblur | reveal cover, descriptions
    setTimeout(() => {
      if(window.hc.screenIndex !== 3) return

      document.querySelector('.teamcontainer .portraits .cover').classList.add('reveal')

      // descriptions
      document.querySelectorAll('.teamcontainer .descriptioncontainer .descriptions *').forEach((item, i) => {

        gsap.to(item, 0.4, {
          opacity: 1,
          transform: 'translateY(0)',
          delay: 0.1 * i
        })

      })

      gsap.to(document.querySelector('.teamcontainer .grid-toggler'), 0.4, { opacity: 1, delay: 0.4 })

      setTimeout(() => {
        if(window.hc.screenIndex !== 3) return

        this.setState({ init: true })
        $('.slick-slider').addClass('active') // highlight card right after unblur
      }, 1200)

    }, 1200)
  }

  componentWillUnmount() {

    clearInterval(this.personInterval)

  }

  componentDidUpdate(prevProps, prevState) {

    if(this.state.teamGridView !== prevState.teamGridView) {

      // grid layout
      if(this.state.teamGridView) Scrollbar.initAll()

      // slider layout
      else this.initSlider()

    }

    if(this.state.human !== prevState.human) {

      if(this.state.teamGridView) {
        // grid transition

        // don't highlight cards while blurred / loading
        if(this.state.init) {

          $('.teamgrid .item').removeClass('active')

          const nextCard = $('.teamgrid .item')[window.hc.content.main.people.indexOf(this.state.human)]
          nextCard.classList.add('active')

        }

        // auto scroll
        const scrollbar = Scrollbar.getAll()[0] // assuming only one data-scroll documentElement
        const nextCard = document.querySelector('.item.active')

        if(nextCard && !this.state.teamGridHovering) {
          scrollbar.update()
          scrollbar.scrollTo(0, nextCard.offsetTop, 1000)
        }

      } else if(this.slider) {
        // slider transition
        this.slider.on('afterChange', () => {

          const sliderIndex = this.slider.slick('slickCurrentSlide')

          this.setState({ human: window.hc.content.main.people[sliderIndex] })


          // hide description container
          $('.slick-slide .item .descriptioncontainer').css({ opacity: 0, transform: 'translateY(20px)' })
          $('.teamcontainer .portraits .photos .item img').css('opacity', 1)


        })

         this.slider.slick('slickGoTo', window.hc.content.main.people.indexOf(this.state.human))

      }

      this.resetTimer()

    }

  }

  initSlider() {
    this.slider = $('.photos #container')

    this.slider.on('afterChange', (event, slick) => {
      // need to animate cards back in since grid view toggler re-inits slider
      // using this as a slider init callback as well

      this.resetTimer()

      // slider cards
      document.querySelectorAll('.slick-slide').forEach((slide, i) => {

        gsap.to(slide, 0.4, {
          opacity: 1,
          transform: 'translateY(0)',
          delay: 0.1 * i
        })

      })

    })

    this.slider.slick({
      arrows: false,
      responsive: [
        {
          breakpoint: 740, // must match css query
          settings: { slidesToShow: 1 }
        }
      ]
    })

    const currentIndex = window.hc.content.main.people.indexOf(this.state.human)
    this.slider.slick('slickGoTo', currentIndex > -1 ? currentIndex : 0)

  }

  toggleLayout() {

    // destroy component functionalities before unmounting
    if(this.state.teamGridView) Scrollbar.destroyAll()
    else if(this.slider) this.slider.slick('unslick')

    this.setState({
      teamGridView: !this.state.teamGridView,
      teamGridHovering: false
    })

  }

  onGridHover(person) {

    this.setState({
      human: person,
      teamGridHovering: true
    })

  }

  onGridHoverOut(person) {

    this.setState({ teamGridHovering: false })

  }

  onGridClick(person) {

    Scrollbar.destroyAll()

    this.setState({
      teamGridView: false,
      human: person
    })

  }

  onClickMobileViewMore(blur = false) {

    if(window.innerWidth > 600) return

    // reset slider
    if(blur) {

      if(!this.personInterval) this.personInterval = setInterval(() => { this.updateContent() }, this.personIntervalTime)

    }

    // pause slider
    else {

      clearInterval(this.personInterval)
      this.personInterval = undefined

      gsap.to(document.querySelector('.teamcontainer .navigation #progress'), 0.4, { height: '100%' })

    }


    // show / hide description container
    const allCardImages = $('.teamcontainer .portraits .photos .item img')

    $('.slick-slide.slick-active .item .descriptioncontainer').css({
      opacity: blur ? 0 : 1,
      transform: blur ? 'translateY(20px)' : 'translateY(0px)'
    })

    allCardImages.css('opacity', blur ? 1 : 0.6)

  }

  updateContent(option) {

    if(this.state.teamGridView && this.state.teamGridHovering) return // don't change content if hovering on cards

    // udpate person description
    const humanIndex = window.hc.content.main.people.indexOf(this.state.human)

    let nextHuman

    if(option === 'reverse') {
      // previous person
      nextHuman = humanIndex <= 0 ?
                  window.hc.content.main.people[window.hc.content.main.people.length - 1] :
                  window.hc.content.main.people[humanIndex - 1]

    } else {
      // next person
      nextHuman = humanIndex < (window.hc.content.main.people.length - 1) ?
                        window.hc.content.main.people[humanIndex + 1] :
                        window.hc.content.main.people[0]
    }

    this.setState({ human: nextHuman })

  }

  resetTimer() {

    // progress bar
    const intervalTime = this.personIntervalTime / 1000 // seconds to milliseconds

    gsap.to(document.querySelector('.teamcontainer .navigation #progress'), intervalTime * 0.1, { height: 0 })

    gsap.to(document.querySelector('.teamcontainer .navigation #progress'), intervalTime * 0.9, {
      delay: intervalTime * 0.1,
      height: '100%'
    })


    clearInterval(this.personInterval)
    this.personInterval = setInterval(() => { this.updateContent() }, this.personIntervalTime)


  }

  render_photos() {

    return (
      <div id="container">
        {
          window.hc.content.main.people.map(person => {

            return (
              <div className="item" key={person.employee_name}>
                <img src={person.photo} alt="Team member headshot" />
                {this.render_descriptioncontainer(person)}
              </div>
            )

          })
        }
      </div>
    )

  }

  render_photo_grid() {

    return (
      <div className="teamgrid" data-scrollbar>

        {
          window.hc.content.main.people.map((person, i) => {
          return (
            <div className={"item" + (i === 0 ? ' active' : '')}
                 key={i}
                 onClick={() => { this.onGridClick(person) }}
                 onMouseEnter={() => { this.onGridHover(person) }}
                 onMouseLeave={() => { this.onGridHoverOut(person) }}>
              <img src={person.photo} alt="team member" />

                <div className="details">
                  <div className="descriptions">
                    <h2>Project Lead</h2>
                    <h1>Lorem Ipsum</h1>
                    <div className="summary">A very short description. project lead who knows exactly what he’s doing</div>
                  </div>
                </div>
            </div>
            )
          })
        }

      </div>
    )
  }

  render_descriptioncontainer(human) {

    return (
      <div className="descriptioncontainer">
        <div className="descriptions">
          <h2>{human ? (this.props.i18n.language === 'en' ? human.job_title : human.job_title_fr) : undefined}</h2>
          <h1>{human ? human.employee_name : undefined}</h1>
          <div className="summary">{human ? (this.props.i18n.language === 'en' ? human.description : human.description_fr) : undefined}</div>
        </div>
      </div>
    )
  }

  render() {

    const { teamGridView, human } = this.state;
    const cardView = window.innerWidth < 950
    const {t, i18n} = this.props;

    return (
      <div className='teamcontainer'>

        <Helmet>
          <title>HUMANCONTACT | Humans</title>
          <meta name="HUMANCONTACT" content="Meet our team at HumanContact" />
        </Helmet>

        <div className="navigation" style={{ display: teamGridView ? 'none' : 'block' }}>
          <h4>{String(window.hc.content.main.people.indexOf(human) + 1).padStart(2,0)}</h4>
          <h4>{String(window.hc.content.main.people.length).padStart(2,0)}</h4>
          <div id="progress"></div>

          <div id="chevrons">
            <div onClick={() => { this.updateContent() }}><i data-feather="chevron-up" color="white"></i></div>
            <div onClick={() => { this.updateContent('reverse') }}><i data-feather="chevron-down" color="white"></i></div>
          </div>

        </div>

        <div id="mobile-humans-navigator">
          <div id="mobileaboutthishuman"
               tabIndex={0}
               onClick={() => { this.onClickMobileViewMore() }}
               onBlur={() => { this.onClickMobileViewMore(true) }}>{t('View bio')}</div>

           <div id="mobilenexthuman">
             <div onClick={() => { this.updateContent('reverse') }}><i data-feather="chevron-left" color="white"></i></div>
             <div onClick={() => { this.updateContent() }}><i data-feather="chevron-right" color="white"></i></div>
           </div>
        </div>

        {this.render_descriptioncontainer(human)}

        <div className="grid-toggler">
          <span>{t('more humans')}</span>
          <div className={teamGridView ? 'tagline active' : 'tagline'} onClick={() => { this.toggleLayout() }}>
            <div></div>
            <div className="active"></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="portraits">
          <div className="cover" style={{ display: cardView ? 'none' : 'block' }}></div>
          <div className="photos">{ teamGridView ? this.render_photo_grid() : this.render_photos() }</div>
        </div>

      </div>
    )
  }

}

export default withTranslation()(Team);

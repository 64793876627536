import React from 'react';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';

class BrowserNotSupported extends React.Component {

  componentDidMount() {

    if(document.querySelector('.loader')) document.querySelector('.loader').style.display = 'none'
    if(document.querySelector('.menus #menuitems')) document.querySelector('.menus #menuitems').style.display = 'none'
    if(document.querySelector('.menus #hc-logo')) document.querySelector('.menus #hc-logo').onclick = () => { window.location.href = '/' }

  }

  onLoad() {

    gsap.to(document.querySelector('#browserunsupported'), 0.4, {
      opacity: 1,
      marginTop: 0,
      delay: 0.4
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div id="browserunsupported">
        <img src={require('assets/images/icons/chrome-bw.svg')} onLoad={() => { this.onLoad() }} alt="browser icon"  />

        <div className="content">
          <h2>{t('Browser not supported')}</h2>

          <h4>{t('Please use a modern browser to view our digital experience!')}</h4>
        </div>

      </div>
    )
  }
}

export default withTranslation()(BrowserNotSupported);

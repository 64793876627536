// This is a 'Scroll Reveal' feature. Elements fade in when they're visible in the viewport

// Usage:
// import class instance | import ScrollAnimator from 'utils/ScrollAnimator.js'
// new instance | new ScrollAnimator()
// add class 'projectsectionhidden' on sections that should be revealed on scroll
// include css to hide these sections:
// ...
// .projectsectionhidden{
//   opacity: 0;
//   transform: translateY(20px);
//
//   transition: all 0.4s ease;
//
//   &.visible{
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

let timer = null

class ScrollAnimator {
  constructor() {

    this.elements = document.querySelectorAll('.projectsectionhidden')
    this.loading = true

    this.animate()

    document.addEventListener('scroll', () => {

      if(timer !== null) clearTimeout(timer)

      timer = setTimeout(() => { this.animate() }, 50)

     })
  }

  animate() {

    this.elements.forEach((element, i) => {

        const position = element.getBoundingClientRect()
        const offsetAllowed = 100

        // Element is fully visible in screen
        if(position.top >= 0 && position.bottom <= window.innerHeight) {}

        // Element is partially visible in screen
        if(position.top + offsetAllowed < window.innerHeight && position.bottom >= 0) {
          element.classList.add('visible') // using CSS 'transition' to control delay - check '.projectsectionhidden'
        }

    })

  }
}

export default ScrollAnimator;

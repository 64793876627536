import React from 'react';
import { IslandScene } from 'components/IslandScene';

class Scene extends React.Component{

  componentDidMount() {

    window.hc.renderer.destroy(); // prevent multiple render loops on screen change

    new IslandScene();

  }

  render() {
    return <div className="backgroundscene"></div>
  }

}

export default Scene;

import React from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import gsap from 'gsap';
import 'css/App.scss';
import $ from 'jquery';
import axios from 'axios';

import Loader from 'components/Loader';
import Menu from 'components/Menu';
import Renderer from 'utils/Renderer';
import DeviceDetection from 'utils/Fixes';

import Landing from 'screens/Landing';
import About from 'screens/About';
import Work from 'screens/Work';
import Team from 'screens/Team';
import Careers from 'screens/Careers';
import Contact from 'screens/Contact';
import Terms from 'screens/Terms';
import Privacy from 'screens/Privacy';
import NotFound from 'screens/404';
import BrowserNotSupported from 'screens/BrowserNotSupported';

import ProjectRunOttawa from 'screens/projects/RunOttawa';
import ProjectKruger from 'screens/projects/Kruger';
import ProjectKrugerAFH from 'screens/projects/KrugerAFH';
import ProjectFineFettleTea from 'screens/projects/FineFettleTea';
import ProjectAuction from 'screens/projects/Auction4Action';
import ProjectKFC from 'screens/projects/KFC';
import ProjectBDO from 'screens/projects/BDO';
import ProjectCarrot from 'screens/projects/CarrotRewards';
import ProjectRexel from 'screens/projects/Rexel';
import ProjectAnimations from 'screens/projects/ProjectAnimations';

import { backgroundpositions } from 'constants/constants';

window.$ = window.jQuery = $;

window.hc = {
  screenIndex: 0,
  screen: {},
  menu: {},
  three: {},
  renderer: new Renderer(),
  content: {},
  timelines: {},
  map: {},
  device: {}
};

class App extends React.Component {

  state = {
    screenIndex: 0,
    contentLoaded: false, // API
    loading: true // UI
  }

  constructor(props) {
    super(props)

    DeviceDetection(); // specific browser, mobile, etc
    ProjectAnimations();

    window.hc.screen.updateIndex = (screenIndex) => {
      if(this.state.screenIndex !== screenIndex)
        this.setState({ screenIndex })
    }

  }

  componentDidMount() {
    window.hc.appLoaded = true

    // No IE support
    if(window.hc.device.browser.ie && window.location.pathname.indexOf('unsupported') < 0) {
      window.location.href = '/unsupported'
      return
    }

    this.loadContent()

  }

  loadContent() {
    const hc_api = "https://admin.humancontact.com/?rest_route=/hc/v2" // refer to functions.php on admin.humancontact.com for WP-API routes

    axios.get(hc_api + '/content')
      .then((res_content) => {

        axios.get(hc_api + '/projects')
          .then((res_projects) => {

            window.hc.content.main = res_content.data
            window.hc.content.projects = res_projects.data

            console.log(window.hc.content.main)
            console.log(window.hc.content.projects)

            this.setState({ contentLoaded: true })
            // updating state with array doesn't trigger componentDidUpdate, so use a single value state to track loading (or requires immutable fixes)

          })

      })
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.screenIndex !== this.state.screenIndex)
      this.onScreenChange()

  }

  onScreenChange() {

    const { screenIndex } = this.state;

    window.hc.screenIndex = screenIndex;

    // menu
    if(!window.hc.device.browser.edge)
      gsap.to(document.querySelector('.menus .menu'), 0.4, {
        mixBlendMode: screenIndex === 1  ? 'difference' : 'none' // this is also updated from About.js onResize
      })

    // screen transition filler
    gsap.to(document.querySelector('.backgroundcover-left'), 0.4, {
      width: screenIndex === 1 && window.innerWidth < 950 ? 0 : backgroundpositions[screenIndex].left.width,
      left: backgroundpositions[screenIndex].left.left
    })

  }

  render() {

    const { screenIndex, contentLoaded, loading } = this.state

    return (

      <div>

      <Loader contentLoaded={contentLoaded} onLoad={() => { this.setState({ loading: false }) }} />

      {
        !loading ?
          (
            <Router>
              <Menu index={screenIndex} />

              <div id="content">

                <div className="backgroundcover-left"></div>
                <div className="backgroundcover-right"></div>

                <Switch>
                  <Route path="/" exact component={Landing} />
                  <Route path="/about" exact component={About} />
                  <Route path="/work" exact component={Work} />

                  <Route path="/work/run-ottawa" exact component={ProjectRunOttawa} />
                  <Route path="/work/kruger-products" exact component={ProjectKruger} />
                  <Route path="/work/kruger-afh" exact component={ProjectKrugerAFH} />
                  <Route path="/work/fine-fettle-tea" exact component={ProjectFineFettleTea} />
                  <Route path="/work/auction-4-action" exact component={ProjectAuction} />
                  <Route path="/work/kfc" exact component={ProjectKFC} />
                  <Route path="/work/bdo" exact component={ProjectBDO} />
                  <Route path="/work/carrot-rewards" exact component={ProjectCarrot} />
                  <Route path="/work/rexel" exact component={ProjectRexel} />
                  <Route path="/work/*" exact render={() => <Redirect to="/work" />} />

                  <Route path="/humans" exact component={Team} />
                  <Route path="/careers" exact component={Careers} />
                  <Route path="/contact" exact component={Contact} />

                  <Route path="/terms" exact component={Terms} />
                  <Route path="/privacy" exact component={Privacy} />

                  <Route path="/unsupported" component={BrowserNotSupported} />
                  <Route component={NotFound} />
                </Switch>

              </div>
            </Router>
          ) :
          undefined
      }

      </div>

    )
  }
}

export default App;

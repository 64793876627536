import React from 'react';
import ReactPlayer from 'react-player';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import CloseButton from 'components/ButtonCloseProject';

class Rexel extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()

  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div id="project-rexel" className="projectdetailcontainer">

        <CloseButton />
        <div id="projectdetailglass"></div>

        <div className="banner projectsectionhidden">
          <video width="100%" height="auto" controls={true} muted={true} autoPlay={true} loop={false} poster={require('assets/images/projects/_covers/rexel-cover.jpg')}>
            <source src="https://player.vimeo.com/external/196495177.hd.mp4?s=96144008ff1e092f16c0053b1148791dffe7a1c7&profile_id=119" type="video/mp4" />
            {t('Your browser does not support the video tag.')}
          </video>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Video & Motion')}</h4>
            <h4>{t('Web & Interactive')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img src={require('assets/images/projects/rexel/logo.png')} alt="project logo" />

          <p>
            {i18n.language === 'en' ? "Rexel is a global leader in the wire & cable industry, but its internal and external communications strategy was antiquated - leading to inefficient processes, product education, and sub optimal sales. We were asked to assist in creating a consistent look and feel across internal materials as well as create modern and dynamic sales aids. By doing nothing other than this, sales increased over 40%." : "Rexel est un leader mondial dans l'industrie des fils et des câbles. Toutefois, sa stratégie de communications internes et externes était obsolète. Dans certains cas, elle rendait certains processus inefficaces, tels que la formation sur les nouveaux produits. Dans d'autres cas, elle engendrait des ventes sous-optimales. Ainsi, Rexel a demandé à Human Contact d'aider l'entreprise à créer une image et une expérience uniforme au sein de tous les outils de communication internes. Human Contact s'est également occupé de la création et de la modernisation des outils pour dynamiser les ventes. En ne faisant rien que cela, leurs ventes ont augmenté de 40 %."}
          </p>
        </div>

        <div className="indicatorcontainer projectsectionhidden">
          <div className="indicator">{t('3D Visualization & Product Videos')}</div>
        </div>
        <div className="productvideo projectsectionhidden">
          <div className="player-wrapper">
            <ReactPlayer
              url='https://vimeo.com/344358885'
              width="100%"
              height="100%"
              className="react-player"
              light={require('assets/images/projects/_covers/rexel-product.png')}
              controls={true}
              playing={false} />
          </div>
        </div>

        <div className="photo1 projectsectionhidden">
          <div className="indicatorcontainer">
            <div className="indicator">{t('Photography')}</div>
          </div>
          <img src={require('assets/images/projects/rexel/stuff.jpg')} alt="project people" />
        </div>

        <div className="photo2 projectsectionhidden">
          <div className="indicatorcontainer">
            <div className="indicator">{t('Policy Manual')}</div>
          </div>
          <img src={require('assets/images/projects/rexel/books.jpg')} alt="project policy guide" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(Rexel);

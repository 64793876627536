import React from 'react';
import ScrollAnimator from 'utils/ScrollAnimator';
import { withTranslation } from 'react-i18next';
import gsap from 'gsap';
import CloseButton from 'components/ButtonCloseProject';

class KrugerAFH extends React.Component {

  componentDidMount() {
    window.hc.timelines.projectDetailIn()

    new ScrollAnimator()

    gsap.fromTo(document.querySelector('#project-kruger-afh .banner h1'), { opacity: 0 }, { opacity: 1, delay: 0.25 });
  }

  componentWillUnmount() {
    window.hc.timelines.projectDetailOut()
  }

  render() {
    const { t, i18n } = this.props;
    const french_version = i18n.language === 'fr' ? '-fr' : '';

    return (
      <div id="project-kruger-afh" className="projectcontainer projectdetailcontainer">

        <CloseButton />

        <div className="banner">
          <img src={require('assets/images/projects/kruger-afh/afh-banner.jpg')} />
          <h1>{t('Be Bold')}<span>.</span></h1>
        </div>

        <div className="services projectsectionhidden" onClick={() => {
          document.querySelector('.projectdetailcontainer .description').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
          }}>
          <div>
            <h4>{t('Strategy & Consultation')}</h4>
            <h4>{t('Design & Branding')}</h4>
            <h4>{t('Communications')}</h4>
            <h4>{t('Web & Interactive')}</h4>
          </div>
        </div>

        <div className="description projectsectionhidden">
          <img src={require('assets/images/projects/kruger-afh/logos' + french_version + '.png')} alt="kruger logo" />
          <p>
            {i18n.language === 'en' ? "Kruger Products' Away from Home division provides solutions to property management, institutional, foodservice, and hospitality businesses. With hygiene top of mind during the COVID-19 pandemic, they were looking for a way to communicate the benefits of roll towels and touchless dispensers as a safe and sanitary choice to their audience, as part of an overall awareness and promotional campaign. We worked together to deliver peace of mind to customers and employees, with an edgy and modern flair, while focusing on the proven benefits of roll towers over air dryers." : "La division des Produits hors foyer de Produits Kruger fournit des solutions aux entreprises de gestion immobilière, aux institutions, aux services alimentaires et aux secteurs hôteliers. L'hygiène étant au cœur des préoccupations pendant la pandémie de COVID-19, l'entreprise cherchait un moyen de communiquer à son public les avantages des serviettes en rouleau et des distributeurs sans contact comme choix sûr et sanitaire, dans le cadre d'une campagne de sensibilisation et de promotion globale. Nous avons travaillé ensemble pour apporter la tranquillité d'esprit aux clients et aux employés, avec une touche de modernité, tout en mettant l'accent sur les avantages avérés des essuie-mains en rouleau par rapport aux séchoirs à air."}
          </p>
        </div>

        <div className="papertowels">
          <div className="indicator">{t('Titan Bold 3D and Bathroom')}<br />{t('Rendering')}</div>
          <img src={require('assets/images/projects/kruger-afh/towels.jpg')} alt="Very cool paper towels render" />
        </div>

        <div className="gloomytowels">
          <div className="indicator">{t('Custom Tiled Motif')}</div>
          <img src={require('assets/images/projects/kruger-afh/gloomytowels.jpg')} alt="Very cool paper towels render" />
        </div>

        <div className="towelicons">
          <div className="indicator">{t('Icons')}</div>
          <img src={require('assets/images/projects/kruger-afh/towel-icons.png')} alt="Very cool paper towels icons" />
        </div>

        <div className="copywriting">
          <div className="indicator">{t('Copywriting')}</div>
          <img src={require('assets/images/projects/kruger-afh/copywriting.png')} alt="Very cool paper towels icons" />
        </div>

        <div className="infographic">
          <div className="indicator">{t('Infographic')}</div>
          <img src={require('assets/images/projects/kruger-afh/infographic.jpg')} alt="infographic poster" />
        </div>

        <div className="socialposts">
          <div className="indicator">{t('socialposts')}</div>
          <img id="tablet" src={require('assets/images/projects/kruger-afh/tablet.png')} alt="Tablet item" />
          <img id="phone" src={require('assets/images/projects/kruger-afh/phone.png')} alt="Phone item" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(KrugerAFH);

import gsap from 'gsap';
import $ from 'jquery';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';

import { breakpoints } from 'constants/constants';

let sceneRendererID
let lowFrameRate = 0

const times = []
let fps;
let monitorFPS = true;
setTimeout(() => { monitorFPS = false }, 10000);

// DOM mouse position | Scene Camera mouse position (Relative to window)
let mouse = {};
let cameraMouse = {};

let camera, scene, renderer, controls, container;
let light1, light2, light3, light4;

let mesh_island_target_rotationX = 0;
let mesh_island_target_rotationZ = 0;

let glitchPass, composer;

const state = {
  islandActive: false,
  glitchTimeout: false, // break from glitch
}

const meshNames = { island: 'island', video: 'video' }
const fogVisibility = { hidden: 120, visible: 500 }

const colors = {
  gold: 0xe8bf2f,
  blue: 0x569ba3,
  beige: 0xdbdbd3,
  black: 0x111111,
  scene: 0x111111,
  sceneInverted: 0xebebe9
}

const islandPositions = {
  desktop: -22,
  tablet: -14
}

class Mouse{
  static update(e){
    Mouse.updateDOM(e);
    Mouse.updateCamera(e);
  }

  static updateDOM(event){
    // DOM mouse position | 0.00 -> 1.00 (decimal %)
    mouse.x = ( event.clientX - window.innerWidth/2 );
    mouse.y = ( event.clientY - window.innerHeight/2 );

    mesh_island_target_rotationX = mouse.y * 0.0002;
    mesh_island_target_rotationZ = mouse.x * 0.0001;
  }

  static updateCamera(event){
    // Scene Camera mouse position
    // 2D X/Y positions relative to window, where camera.position.z = 0
    let vector = new THREE.Vector3();
    vector.set(
        ( event.clientX / window.innerWidth ) * 2 - 1,
        - ( event.clientY / window.innerHeight ) * 2 + 1,
        0.5 );
    vector.unproject( camera );
    let dir = vector.sub( camera.position ).normalize();
    let distance = - camera.position.z / dir.z;
    let pos = camera.position.clone().add( dir.multiplyScalar( distance ) );
    cameraMouse.x = pos.x;
    cameraMouse.y = pos.y;
  }
}

export class IslandScene{
  constructor(timelines) {

    this.init();

    this.mesh = {};

    window.mesh = this.mesh;
    window.hc.three.rotationVelocity = 1 // 1 is regular speed

  }

  init(){

    container = document.querySelector('.backgroundscene');

    camera = new THREE.PerspectiveCamera(28, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.x = 0;
    camera.position.y = 5;
    camera.position.z = 210;

    scene = new THREE.Scene();
    scene.background = new THREE.Color( colors.scene ); // white background

    light1 = new THREE.PointLight( 0xffffff, 1, 500 );
    light2 = new THREE.PointLight( 0xffffff, 1, 500 );
    light3 = new THREE.PointLight( 0xffffff, 0, 500 );
    light4 = new THREE.PointLight( 0x000000, 1, 500 );
    light1.position.set( 250, -50, 50 );
    light2.position.set( 50, 250, 50 );
    light3.position.set( -50, 50, 100 );
    light4.position.set( -50, 50, 80 );

    light3.name = 'dimmer'

    scene.add( light1, light2, light3, light4 );

    scene.fog = new THREE.Fog( colors.scene, 20, fogVisibility.visible );

    //
    this.loadIsland()
    // this.loadVideo()

    //
    initRenderer();
    initControls();

    function initRenderer(){
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);

      // post processing
      composer = new EffectComposer( renderer );
      composer.addPass( new RenderPass( scene, camera ) );
      glitchPass = new GlitchPass();
      composer.addPass( glitchPass );
      composer.setSize( window.innerWidth, window.innerHeight );

      glitchPass.enabled = false
      glitchPass.goWild = true

      window.hc.three.glitch = glitchPass
      window.hc.three.glitchOnce = (duration = 600) => {

        if(glitchPass.enabled || state.glitchTimeout) return

        glitchPass.goWild = true
        glitchPass.enabled = true

        setTimeout(() => { glitchPass.enabled = false }, duration)
      }

      container.appendChild(renderer.domElement);
    }
    function initControls(){
      controls = new TrackballControls(camera, renderer.domElement);
      controls.rotateSpeed = 0;
      controls.zoomSpeed = 2.2;
      controls.panSpeed = 1;
      controls.dynamicDampingFactor = 0.3;
    }
    //
    window.addEventListener('resize', IslandScene.onWindowResize, false);
    window.addEventListener('mousemove', Mouse.update, false);

    this.animate()

    window.hc.three.scene = scene;
    window.hc.three.meshes = {}

    window.hc.three.loadVideo = this.loadVideo;

    window.hc.three.toggleIsland = this.toggleIsland;
    window.hc.three.toggleFog = this.toggleFog;
    window.hc.three.toggleVideo = this.toggleVideo;

  }

  loadIsland() {

    console.log("Call window.hc.three.toggleIsland(true / false) to display / hide island mesh");

    let loader = new OBJLoader();

    // might need different offsetY (mesh position Y) for each item - to keep them centered in the scene
    let modelFile = () => {
      const models = [
        {
          src: require('assets/models/headphones.obj'),
          offsetY: -24
        },
        {
          src: require('assets/models/coffee.obj'),
          offsetY: -24
        },
        {
          src: require('assets/models/notebook.obj'),
          offsetY: 0
        },
        {
          src: require('assets/models/laptop.obj'),
          offsetY: -24
        },
        {
          src: require('assets/models/slate.obj'),
          offsetY: -24
        },
      ]

      const index = Math.floor(Math.random() * (models.length - 1) ) + 0

      return models[index]
    }

    const randomizeModel = modelFile()

    loader.load(
      randomizeModel.src,
      ( object ) => {

        // resource is loaded
        const island_wireframe = false;
        // const island_material = new THREE.MeshPhongMaterial({ color: 0xf8f8f8, transparent: true, opacity: 0 });
        const island_material = new THREE.MeshPhongMaterial({ color: 0x555555, transparent: true, opacity: 0 });
        const model_scale = 1.25; // island default: 0.2
        object.scale.set(model_scale,model_scale,model_scale);

        object.children.forEach((child, i) => {

          child.material = island_material;
          child.material.wireframe = island_wireframe;

        })

        object.position.y = window.innerWidth < breakpoints.l ? islandPositions.tablet : islandPositions.desktop
        object.position.y = randomizeModel.offsetY || 0;
        object.name = meshNames.island;

        scene.add(object);

        this.mesh.island = true;
        window.hc.three.meshes.island = object

        this.toggleIsland(window.hc.screenIndex === 2)


      },
      ( xhr ) => {
        // const percentLoaded = (xhr.loaded / xhr.total * 100)
      },  // called when loading is in progresses
      ( error ) => { console.error(error) }
    );

  }

  loadVideo() {

    const video = $('.aboutcontainer #aboutvideo')

    const texture = new THREE.VideoTexture( video[0] )
    const geometry = new THREE.PlaneGeometry( 50, 50, 10 )
    const material = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      map: texture,
      transparent: true,
      opacity: 0
    })

    const mesh = new THREE.Mesh( geometry, material )
    mesh.position.z = 20
    mesh.name = meshNames.video
    mesh.source = video[0]

    scene.add(mesh)
    window.hc.three.meshes.video = mesh

    video[0].play()
    window.hc.three.toggleVideo(true)

  }

  toggleFog(option) {

    switch(option) {
      case 'in':
      gsap.to(window.hc.three.scene.fog, 1, { far: fogVisibility.visible });
      break;
      case 'out':
      gsap.to(window.hc.three.scene.fog, 1, { far: fogVisibility.hidden }) // 120 hides completely
      break;
      case undefined:
      console.warn("toggleFog() option parameter not specified. Use 'in' or 'out' as parameter");
      break;
      default:
      console.warn('Could not toggle mesh');
      break;
    }

  }

  toggleIsland(visible) {

    if(!window.hc.three.meshes.island) return

    const island = scene.getObjectByName(meshNames.island)
    const opacity = visible ? 1 : 0
    const duration = 0.4

    island.children.forEach((mesh, i) => {

      if(mesh.material.length)
        mesh.material.forEach(material => {
          material.transparent = true
          gsap.to(material, duration, { opacity: opacity })
        })
      else {
        mesh.material.transparent = true
        gsap.to(mesh.material, duration, { opacity: opacity })
      }

    })

  }

  toggleVideo(visible) {
    const video = window.hc.three.scene.getObjectByName('video')

    video.material.transparent = true
    gsap.to(video.material, 0.4, { opacity: visible ? 1 : 0 })
  }

  checkFPS() {
    const now = performance.now();
    while (times.length > 0 && times[0] <= now - 1000) { times.shift(); }
    times.push(now);
    fps = times.length;
    if(fps < 20) lowFrameRate++ // < 20 fps
    if(lowFrameRate > 30) { // for 30 times
      window.hc.renderer.remove(sceneRendererID)
      $('.backgroundscene').hide()
      $('body').css('background-color', '#111111')
      $('.workscenereplacement').show()
    }
  }

  animate() {

    sceneRendererID = window.hc.renderer.add(() => { this.render() });

  }

  render() {

    // FPS MONITOR ========================================================

    // if the FPS is consistently low. Stop rendering / remove the scene
    // and replace it with a background color / image


    if(monitorFPS) this.checkFPS()
    // ====================================================================


    // SCENE UPDATES
    controls.update();

    // don't animate object on mobile & IE
    if(!window.hc.device.isMobile && !window.hc.device.browser.ie) {

      // island
      if(this.mesh) {

        const islandscene = scene.getObjectByName('island');

        if(islandscene) {

          islandscene.rotation.y += 0.004 * window.hc.three.rotationVelocity;
          islandscene.rotation.x += (mesh_island_target_rotationX - islandscene.rotation.x) / 6;
          islandscene.rotation.z += (mesh_island_target_rotationZ - islandscene.rotation.z) / 6;

          IslandScene.onWindowResize()

          if(window.hc.screenIndex !== 2 && islandscene.visible) islandscene.visible = false
          else if(window.hc.screenIndex === 2 && !islandscene.visible) islandscene.visible = true

        }

      }

    }

    composer.render(); // original: renderer.render( scene, camera );

  }

  static onWindowResize(){

    // container size
    // const container = document.querySelector('.backgroundscene');
    // const width = container.offsetWidth;
    // const height = container.offsetHeight;
    //
    // camera.aspect = width / height;
    // camera.updateProjectionMatrix();
    //
    // renderer.setSize(width, height);

    // browser size
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);

    if(window.hc.three.meshes.island) {

      if(window.innerWidth < breakpoints.l) window.hc.three.meshes.island.position.x = islandPositions.tablet
      else window.hc.three.meshes.island.position.x = islandPositions.desktop

    }
  }
}
